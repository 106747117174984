// Core
import { C_Style } from './constans.js';
import * as THREE from 'three';

export class Path {

    constructor(scene, points, scale) {

        // Convert the array of points into vertices (in Blender the z axis is UP so we swap the z and y)
        for (var i = 0; i < points.length; i++) {
            var x = points[i][0] * scale;
            var y = points[i][1] * scale;
            var z = points[i][2] * scale;
            points[i] = new THREE.Vector3(x, z, -y);
        }

        // Create a path from the points
        this._cameraPath = new THREE.CatmullRomCurve3(points);

        var geometry = new THREE.TubeGeometry(this._cameraPath, 500, .3, 10, false);
        var material = new THREE.MeshBasicMaterial({ color: 0x07b0f0, side: THREE.DoubleSide });

        // Create the mesh
        var tube = new THREE.Mesh(geometry, material);
        tube.position.y = 2;

        // Insert mesh to scene
        scene.add(tube);

        // Point Conector
        var pointConector = new THREE.Mesh(
            new THREE.CylinderGeometry(1, 1, 1, 32),
            new THREE.MeshStandardMaterial({ color: 0x07b0f0, transparent: true, opacity: 0.9 })
        );
        pointConector.position.set(-21, 2, 26.7);

        // Insert connector to scene
        scene.add(pointConector);

    }

    update() {

    }
}