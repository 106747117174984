import * as THREE from 'three';
import { C_Style } from './constans.js';
import { mathRandom } from './utils.js';
import { TweenMax, Linear } from 'gsap';
import { MathUtils } from 'three/src/math/MathUtils.js';

export class Particles {

    constructor(scene, camera, count) {

        this.group = new THREE.Group();
        this.groupArray = [];
        this.particleDirection = true;
        this.particleSpeed = 0.001;
        this.particleColors = [0xff6d20, 0x07b0f0, 0xfaed00];

        for (var i = 0; i < count; i++)
            this.buildParticle(8, 30);

            return this;
    }

    buildParticle(scale = 2, startPosition = 20, cColor = 0x07b0f0) {

        this.particleStartPosition = startPosition;

        cColor = this.particleColors[Math.floor(Math.random() * 3)];

        var particlePoints = [];
        particlePoints.push(new THREE.Vector3(-1, 0, 0));
        particlePoints.push(new THREE.Vector3(mathRandom(2) / 10, 0, 0));

        var particleMaterial = new THREE.MeshStandardMaterial({ color: cColor, side: THREE.DoubleSide, transparent: true, emissive: cColor, emissiveIntensity: 10.0 });
        
        //var particleMaterial = new THREE.MeshBasicMaterial({ color: cColor, transparent: true, combine: THREE.AddOperation, fog: false });
        /*
        var particleGeometry = new THREE.BufferGeometry().setFromPoints(particlePoints);
        var particleMesh = new THREE.Line(particleGeometry, particleMaterial);
        */
        var particleGeometry = new THREE.BoxGeometry(mathRandom(3), 0.05, 0.05);
        var particleMesh = new THREE.Mesh(particleGeometry, particleMaterial);

        var cAmp = 30;
        var C_JumpZ = 16.65;
        var C_JumpX = 16.65;

        var JumpsZ = [];
        var JumpsX = [];

        for(var i = -3 ; i < 3 ; i ++) {
            JumpsZ.push(C_JumpZ * i);
            JumpsX.push(C_JumpX * i);
        }

        if (this.particleDirection) {
            this.particleDirection = false;
            particleMesh.position.x = -startPosition;
            particleMesh.position.z = JumpsZ[Math.floor(Math.random() * JumpsZ.length)]; // (Utils.mathRandom(cAmp));
            particleMesh.name = 'x';
            particleMesh.userData = {
                tween: TweenMax.to(particleMesh.position, 2.5, { x: startPosition, repeat: -1, yoyo: true, delay: mathRandom(3), ease: Linear.easeNone })
            };
        } else {
            this.particleDirection = true;
            particleMesh.position.x = JumpsX[Math.floor(Math.random() * JumpsX.length)]; // (Utils.mathRandom(cAmp));
            particleMesh.position.z = -startPosition;
            particleMesh.rotation.y = 90 * Math.PI / 180;
            particleMesh.name = 'z';
            particleMesh.userData = {
                tween: TweenMax.to(particleMesh.position, 2.5, { z: startPosition, repeat: -1, yoyo: true, delay: mathRandom(3), ease: Linear.easeNone }) // , paused: true
            };
        };

        particleMesh.position.y = 0; // Math.abs(mathRandom(100));
        particleMesh.material.needsUpdate = true;

        this.groupArray.push(particleMesh);
        this.group.add(particleMesh);
    }

    update(time, globalOpacity) {

        this.groupArray.forEach(
            (particle) => {

                if (particle.name == 'x') {
                    var absPost = Math.abs(particle.position.x);
                    var opacity = MathUtils.smoothstep(absPost, 20, this.particleStartPosition);
                } else {
                    var absPost = Math.abs(particle.position.z);
                    var opacity = MathUtils.smoothstep(absPost, 20, this.particleStartPosition);
                }

                particle.material.opacity = MathUtils.lerp(1, 0, opacity);
                
            }
        );

    }

    renderAll(globalOpacity) {
        this.groupArray.forEach(
            (particle) => {
                particle.material.opacity -= globalOpacity;
            }
        );
    }

    resume() {
        this.groupArray.forEach(particle => particle.userData.tween.resume());
    }

    pause() {
        this.groupArray.forEach(particle => particle.userData.tween.pause());
    }
}