// Core
import { C_Style } from './constans.js';
import * as THREE from 'three';

export class Roads {
       
    constructor(scene, points, scale, radius, color, position) {

        for (var i = 0; i < points.length; i++) {
            var x = points[i][0] * scale;
            var y = points[i][1] * scale;
            var z = points[i][2] * scale;
            points[i] = new THREE.Vector3(x, z, -y);
        }

        this.curve = new THREE.CatmullRomCurve3(points);

        var geometry = new THREE.TubeGeometry(this.curve, 600, radius, 10, false);
        //var material = new THREE.MeshBasicMaterial({ color: color });
        var material = new THREE.MeshStandardMaterial({ color: color, emissive: 0x410c0c, emissiveIntensity: 5.0 })
        
        var tube = new THREE.Mesh(geometry, material);
        tube.position.set(position.x, position.y, position.z);
        scene.add(tube);

        /*
        var line = new THREE.Line( 
            new THREE.BufferGeometry().setFromPoints( new THREE.SplineCurve(points).getPoints(50) ), 
            new THREE.LineBasicMaterial({
                color: 0x0000ff
            })
        );

        scene.add(line);
        */
    }

    update() {

    }
}