// Core
import { C_Style } from './constans.js';
import * as THREE from 'three';

export class Plane {
    constructor(scene, settings) {
        this._scene = scene;
        this._settings = settings;
        this.buildPlane();
    }
    buildPlane() {

        // Grid
        const gridHelper = new THREE.GridHelper(3000, 180, C_Style.accent, C_Style.accent);
        gridHelper.material.transparent = true;
        gridHelper.layers.set(0);
        this._scene.add(gridHelper);

        // Grid extension (point clouds)

        const gridGeometry = new THREE.PlaneBufferGeometry(this._settings.width, this._settings.height, 180, 180, 180);
        // add an attribute
        const gridNumVertices = gridGeometry.attributes.position.count;
        var alphas = new Float32Array(gridNumVertices * 1); // 1 values per vertex

        for (var i = 0; i < gridNumVertices; i++) {
            // set alpha randomly
            alphas[i] = Math.random();
        }

        gridGeometry.setAttribute('alpha', new THREE.BufferAttribute(alphas, 1));

        // uniforms
        const gridUniforms = {
            color: { value: new THREE.Color(0xffff00) }, //0xffff00
            fogC: {
                type: "c",
                value: new THREE.Color(C_Style.fogColor)
              },            
              fogColor: {
                type: "c",
                value: new THREE.Color(C_Style.fogColor)
              },
              fogFar: {
                type: "f",
                value: C_Style.fogFar
              },
              fogNear: {
                type: "f",
                value: C_Style.fogNear
              },              
        };

        // point cloud material
        const gridMaterial = new THREE.ShaderMaterial({
            uniforms: gridUniforms,            
            vertexShader: `               
                attribute float alpha;
                varying float vAlpha;
                uniform float scale;
                
                /*
                uniform vec4 origin;
                */
            
                void main() {
                    vAlpha = alpha;
                    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );

                    /*
                    float cameraDist = distance( mvPosition, origin );
                    gl_PointSize = mix(0.0, 3.0, (100.0 / cameraDist));
                    */

                    gl_PointSize = 3.0;

                    #ifdef USE_SIZEATTENUATION
                        bool isPerspective = ( projectionMatrix[ 2 ][ 3 ] == - 1.0 );
                        if ( isPerspective ) 
                            gl_PointSize *= ( scale / - mvPosition.z );
                    #endif
                    
                    gl_Position = projectionMatrix * mvPosition;
                }
            `,
            fragmentShader: [           
                'uniform vec3 color;',
                'varying float vAlpha;',

                'uniform vec3 fogC;',
                THREE.ShaderChunk[ "common" ],
                THREE.ShaderChunk[ "fog_pars_fragment" ],       
                
                'void main() {',
                    'gl_FragColor = vec4( color, vAlpha );',

                    'float depth = gl_FragCoord.z / gl_FragCoord.w;',
                    'float fogFactor = smoothstep( fogNear, fogFar, depth );',
                    'gl_FragColor = mix( gl_FragColor, vec4( fogColor, gl_FragColor.w ), fogFactor );',                              

                '}'
            ].join('\n'),
            transparent: true,
            fog: true,
        });

        this._gridCloudMesh = new THREE.Points(gridGeometry, gridMaterial);
        this._gridCloudMesh.rotation.x = Math.PI / 2;
        //this._gridCloudMesh.layers.set(0);
        this._scene.add(this._gridCloudMesh);

        // Plane
        const planeMaterial = new THREE.MeshBasicMaterial({ color: C_Style.dark1 });
        const planeGeometry = new THREE.PlaneBufferGeometry(this._settings.width + 1000, this._settings.height + 1000);
        const planeMesh = new THREE.Mesh(planeGeometry, planeMaterial);

        planeMesh.position.y = -1;
        planeMesh.rotation.x = - Math.PI * 0.5;
        //planeMesh.layers.set(0);

        /*
        planeMesh.receiveShadow = true;
        planeMesh.castShadow = true;
        */

        this._scene.add(planeMesh);

    }
    update() {

        var alphas = this._gridCloudMesh.geometry.attributes.alpha;
        var count = alphas.count;
        for (var i = 0; i < count; i++) {
            alphas.array[i] *= 0.995;
            if (alphas.array[i] < 0.01) {
                alphas.array[i] = 0.4;
            }
        }

        alphas.needsUpdate = true;

    }
}