import * as THREE from 'three';
import { MathUtils } from 'three/src/math/MathUtils.js';
import { Border } from './border.js';
import { Materials } from './materials.js';
import { Particles } from './particles.js';
import { Clouds } from './clouds.js';


export class StageSuccessStories {

    /**
     * StageSuccessStories
     * 
     * @param {THREE.Scene} scene 
     * @param {THREE.Camear} camera 
     * @param {THREE.LoadingManager} loadingManager 
     * @returns 
     */

    constructor(scene, camera, loadingManager) {
        var self = this;

        this.name = 'StageSuccessStories';

        this._scene = scene;
        this._camera = camera;

        // The main clock
        this._clock = new THREE.Clock();

        // Stage container
        this.group = new THREE.Group();
        //this.group.rotation.y = MathUtils.degToRad(180);

        // Add stage container to main scene
        this._scene.add(this.group);

        // Set stage position in local coordinates
        this.group.position.set(-583.38, 0, -250);

        // Swaps to calculate distances
        this._vectorSource = new THREE.Vector3(0, 0, 0);
        this._vectorTarget = new THREE.Vector3(0, 0, 0);

        // By default stage is not playing
        this.isPlaying = false;

        return new Promise((resolve, reject) => {
            self.buildProps();
            resolve(self);
        });

    }

    /**
     * Build all props
     * 
     * @return void
     */
    buildProps() {

        // @Build: Outer circle [orange]
        var outerCircle = new THREE.Mesh(
            new THREE.TorusGeometry(4.0, 0.01, 8, 100),
            new THREE.MeshStandardMaterial({ color: 0xfe4f0e, emissive: 0x410c0c, emissiveIntensity: 2.3 })
        );

        outerCircle.position.y = 0;
        outerCircle.position.z = 0;
        outerCircle.rotation.x = Math.PI / 2;
        outerCircle.scale.set(9, 9, 9);

        this.group.add(outerCircle);

        // @Build: Inner circle [white] 
        var borderCircle = new THREE.Line( // Mesh 
            new Border([
                [-0.9404578804969788, -0.22626015543937683, 0.0],
                [-0.6792352199554443, -0.027554424479603767, 0.0],
                [-0.8808169960975647, 0.1920844316482544, 0.0],
                [-0.6570416688919067, 0.38724756240844727, 0.0],
                [-0.5233525037765503, 0.7116607427597046, 0.0],
                [-0.09229032695293427, 0.6219073534011841, 0.0],
                [0.34710487723350525, 0.7839107513427734, 0.0],
                [0.7676354646682739, 0.5027088522911072, 0.0],
                [0.9493891596794128, 0.38106974959373474, 0.0],
                [0.6805585622787476, 0.11967355012893677, 0.0],
                [0.8365755081176758, -0.22484788298606873, 0.0],
                [0.5357359051704407, -0.41996216773986816, 0.0],
                [0.30478352308273315, -0.695796012878418, 0.0],
                [-0.04465660825371742, -0.9136638641357422, 0.0],
                [-0.5135576725006104, -0.6377986669540405, 0.0],
                [-0.7696616649627686, -0.48536422848701477, 0.0],
            ], .05, 50),
            new THREE.MeshStandardMaterial({ color: 0x07b0f0 /* , emissive: 0x07b0f0, emissiveIntensity: 5.0  */ })
            // new THREE.LineBasicMaterial({ color: 0x07b0f0, emissive: 0x07b0f0, emissiveIntensity: 5.0 })
        );

        borderCircle.position.set(0, 0, 0);
        this.group.add(borderCircle);

        // Particles
        this.particles = new Particles(this._scene, this._camera, 60);
        this.particles.group.position.set(0, 0, 0);
        this.group.add(this.particles.group);

        // Clouds
        this.clouds = new Clouds(this._scene, this._camera);
        this.clouds.group.position.y = 4.5;
        this.group.add(this.clouds.group);
    }

    /**
     * Parse all elements in GLB file and insert into group
     * 
     * @param {THREE.Object3D} object 
     * @returns Promise 
     */
    parse(object) {
        var self = this;

        return new Promise(
            (resolve, reject) => {

                object.scene.traverse(
                    function (item) {

                        if (!item.isMesh)
                            return;

                        var child = item.clone();

                        switch (child.name) {

                            case 'Extras_node':

                                child.material = Materials.solidBuildingExtras();
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);

                                var wireframeChild = new THREE.LineSegments(
                                    new THREE.EdgesGeometry(child.geometry),
                                    Materials.solidBuildingExtrasLine(),
                                );
                                wireframeChild.scale.set(0.3, 0.3, 0.3);
                                self.group.add(wireframeChild);

                                break;
                            case 'Building_ScreenTop_node':
                                
                                self.videoScreenTop = document.getElementById('screen-01');

                                const videoTextureCircular = new THREE.VideoTexture(self.videoScreenTop);
                                videoTextureCircular.format = THREE.RGBFormat;
                                videoTextureCircular.flipY = false;

                                child.material = new THREE.MeshBasicMaterial(
                                    {
                                        map: videoTextureCircular,
                                        side: THREE.DoubleSide,
                                        transparent: true,
                                        opacity: 0.9
                                    }
                                );
                                self._screenCircular = child;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);

                                break;
                            case 'Building_Screen_node':
                                
                                self.videoScreen = document.getElementById('screen-01');

                                const videoTexture = new THREE.VideoTexture(self.videoScreen);
                                videoTexture.format = THREE.RGBFormat;
                                videoTexture.flipY = false;

                                child.material = new THREE.MeshBasicMaterial(
                                    {
                                        map: videoTexture,
                                        side: THREE.DoubleSide,
                                        //transparent: true,
                                        //opacity: 0.9
                                    }
                                );
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);
                                

                                break;
                            case 'Building_ScreenTop_Cap_node':
                                
                                child.material = Materials.blueWireframe();
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);
                            
                            break;
                            case 'Building_node':
                                child.material = Materials.solidBuildingFlat();
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);

                                var wireframeChild = new THREE.LineSegments(
                                    new THREE.EdgesGeometry(child.geometry),
                                    Materials.wireframeSolidBlue()
                                );
                                wireframeChild.scale.set(0.3, 0.3, 0.3);
                                self.group.add(wireframeChild);

                                break;
                            case 'Dome_node':

                                child.material = Materials.blueGlowLight();
                                child.material.needsUpdate = true;
                                //child.position.z -= 1.5;
                                child.scale.set(0.3, 0.15, 0.3);
                                self.group.add(child);

                                // Orange outter circle
                                var TorusMesh = new THREE.Mesh(
                                    new THREE.TorusGeometry(31.5, 0.02, 8, 100),
                                    new THREE.MeshBasicMaterial({ color: 0xffffff })
                                );

                                TorusMesh.position.y = 0;
                                TorusMesh.position.z = 0;
                                TorusMesh.rotation.x = Math.PI / 2;

                                self.group.add(TorusMesh);

                                break;

                            default:
                                var Mesh = new THREE.Mesh(
                                    child.geometry,
                                    Materials.blueWireframe()
                                );
                                Mesh.scale.set(0.3, 0.3, 0.3);
                                self.group.add(Mesh);
                        }


                        resolve(this);
                    }
                );
            }
        );
    }


    /**
     * Return if object is near visible to the camera.
     * 
     * @returns bool
     */
    hasToUpdate() {
        this._vectorTarget.set(
            this._camera.position.x,
            this._camera.position.y,
            this._camera.position.z
        );
        var distance = this.group.position.distanceTo(this._vectorTarget);

        this.globalOpacity = MathUtils.smoothstep(distance, 70, 200);

        return distance < 140;
    }

    /**
     * Play all secondary animations
     * 
     * @returns void
     */
    resume() {
        if (this.isPlaying)
            return;
        this.particles.resume();
        this.videoScreen.play();
        this.videoScreenTop.play();
    }

    /**
     * Puase all secondary animations
     * 
     * @returns void
     */
    pause() {
        if (this.isPlaying == false)
            return;
        this.particles.pause();
        this.videoScreen.pause();
        this.videoScreenTop.pause();
    }

    /**
     * Update loop
     * 
     * @returns void
     */
    update() {

        // Update secondary animations if elements are near visible
        if (this.hasToUpdate()) {
            this.render();

            // Play secondary animations
            this.resume();
            this.isPlaying = true;
        } else {

            // Pause secondary animations
            this.pause();
            this.isPlaying = false;
        }

        // Call to render all primary elements
        this.renderAll();
    }

    /**
     * Render only visible elements
     * 
     * @returns void
     */
    render() {
        var time = this._clock.getDelta();

        this.clouds.update(time);
        this.particles.update(time, this.globalOpacity);

        // Custom code

    }

    /**
     * Render only primary elements
     * 
     * @returns void
     */
    renderAll() {
        this.particles.renderAll(this.globalOpacity);
    }



}