import './assets/css/audio.min.css';

import { 
    TweenMax, Quart } from "gsap";

export class AudioEnviroment {

    constructor() {
        const self = this;

        // Audio Player
        this.elem = document.getElementById('audio-element');
        // UI Player
        this.ctrl = document.getElementById('audio-control');

        this.play = false;
        this.vlmn = 0.7;

        this.elem.addEventListener('play', function () {
            self.play = true;
        }, false);

        this.elem.addEventListener('pause', function (e, t) {
            self.play = false;
        }, false);

        this.ctrl.addEventListener('click', function () {
            if (self.ctrl.classList.contains('stopped')) {
                self.Play();
            } else {
                self.Stop();
            }
        });

        function easeOutVolume(volume, callback) {
            var factor = 0.01,
                speed = 30;

                if (volume > factor) {
                    setTimeout(function () {
                        easeOutVolume(self.elem.volume -= factor, callback);
                }, speed);
            } else {
                (typeof (callback) !== 'function') || callback();
            }
        }

        function easeInVolume(volume, callback) {
            var factor = 0.02,
                speed = 30;

            if (volume < self.vlmn) {
                setTimeout(function () {
                    easeInVolume((self.elem.volume += factor), callback);
                }, speed);
            } else {
                (typeof (callback) !== 'function') || callback();
            }
        }

        window.addEventListener('focus', function () {
            if (self.play === true) {
                easeInVolume(0.05);
                //                elem.volume = vlmn;
            }
        });

        window.addEventListener('blur', function () {
            if (self.play === true) {
                easeOutVolume(0.05);
                //                elem.volume = 0.05;
            }
        });

    }

    Play() {
        const self = this;

        this.ctrl.classList.remove('stopped');
        this.elem.volume = 0;
        this.elem.play();
        
        TweenMax.to(this.elem, 0.5, {
            volume: this.vlmn,
            ease: Quart.easeIn,
        });

    };    

    Stop () {
        const self = this;

        this.ctrl.classList.add('stopped');

        TweenMax.to(this.elem, 0.5, {
            volume: 0,
            ease: Quart.easeOut,
            onComplete: function () {
                self.elem.pause();
            }
        });

    };  

};
