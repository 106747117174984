// Core
import { C_Style } from './constans.js';
import * as THREE from 'three';

export var Materials = {
    /**
     * Gradient Transparent Topology 
     * 
     * @param {*} poColor 
     * @param {*} pvHeight 
     * @param {*} pvOpacity 
     * @returns 
     */
    gradientTransparentTopology: function (poColor, pvHeight, pvOpacity) {
        return new THREE.ShaderMaterial({
            uniforms: {
                color: {
                    value: poColor, // || new THREE.Color(0x07b0f0), 
                },
                hight: {
                    value: pvHeight, // || 15.0
                },
                opacity: {
                    value: pvOpacity,
                },
                fogC: {
                    type: "c",
                    value: new THREE.Color(C_Style.fogColor)
                  },            
                fogColor: {
                    type: "c",
                    value: new THREE.Color(C_Style.fogColor)
                  },
                  fogFar: {
                    type: "f",
                    value: C_Style.fogFar
                  },
                  fogNear: {
                    type: "f",
                    value: C_Style.fogNear
                  },
            
            },
            vertexShader: `               
                varying vec3 vUv; 
                
                void main() {
                    vUv = position; 
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                }
            `,
            fragmentShader: [
                'uniform vec3 color;',              
                'uniform float hight;',
                'uniform float opacity;',
                'varying vec3 vUv;',
                'uniform vec3 fogC;',
                THREE.ShaderChunk[ "common" ],
                THREE.ShaderChunk[ "fog_pars_fragment" ],       
                'void main() {',
                    'float alpha = smoothstep(0.0, opacity, vUv.y/hight);',
                    'gl_FragColor = vec4(color, alpha);',
                    'float depth = gl_FragCoord.z / gl_FragCoord.w;',
                    'float fogFactor = smoothstep( fogNear, fogFar, depth );',
                    'gl_FragColor = mix( gl_FragColor, vec4( fogColor, gl_FragColor.w ), fogFactor );',                              
                '}'
            ].join('\n'),
            wireframe: true,
            transparent: true,
            fog: true,
//            blending: THREE.AdditiveBlending,
        });
    },
    /**
     * Gradient Solid Topology 
     * 
     * @param {} poColor 
     * @param {*} pvHeight 
     * @param {*} pvOpacity 
     * @returns 
     */
    gradientSolidTopology: function (poColor, pvHeight, pvOpacity) {
        return new THREE.ShaderMaterial({
            uniforms: {
                color: {
                    value: poColor, // || new THREE.Color(0x07b0f0), 
                },
                height: {
                    value: pvHeight, // || 15.0
                },
                opacity: {
                    value: pvOpacity,
                },
                fogC: {
                    type: "c",
                    value: new THREE.Color(C_Style.fogColor)
                  },            
                  fogColor: {
                    type: "c",
                    value: new THREE.Color(C_Style.fogColor)
                  },
                  fogFar: {
                    type: "f",
                    value: C_Style.fogFar
                  },
                  fogNear: {
                    type: "f",
                    value: C_Style.fogNear
                  },              
            },
            vertexShader: `               
                varying vec3 vUv; 
       
                void main() {
                    vUv = position; 
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
                }
            `,
            fragmentShader: [
                'uniform vec3 color;',
                'uniform float height;',
                'uniform float opacity;',
                'varying vec3 vUv;',

                'uniform vec3 fogC;',
                THREE.ShaderChunk[ "common" ],
                THREE.ShaderChunk[ "fog_pars_fragment" ],       

                'void main() {',
                    'float alpha = smoothstep(0.0, 1.0, vUv.y/20.0);',
                    'gl_FragColor = vec4(color, alpha);',

                    'float depth = gl_FragCoord.z / gl_FragCoord.w;',
                    'float fogFactor = smoothstep( fogNear, fogFar, depth );',
                    'gl_FragColor = mix( gl_FragColor, vec4( fogColor, gl_FragColor.w ), fogFactor );',                              
                '}'
            ].join('\n'),
            transparent: true,
            fog: true,
        })
    },
    /**
     * Solid Blue Light 
     * 
     * @returns 
     */
    solidBlue: function () {
        return new THREE.MeshBasicMaterial(
            {
                color: 0x07b0f0,
                side: THREE.DoubleSide
            }
        )
    },
    wireframeSolidBlue: function () {
        return new THREE.LineBasicMaterial({
            color: 0x07b0f0,
            transparent: true,
            opacity: 0.5,
        });
    },
    extras: new THREE.MeshPhongMaterial(
        {
            color: 0x07b0f0,
            wireframe: false,
            transparent: true,
            opacity: 0.1,
            blending: THREE.AdditiveBlending,
            flatShading: true
        }
    ),
    extrasWireframe: new THREE.LineBasicMaterial(
        { color: 0xffffff, transparent: true, opacity: 0.2 }
    ),
    /**
     * Solid Building Flat 
     * 
     * @returns 
     */
    solidBuildingFlat: function () {
        return new THREE.MeshStandardMaterial({
            color: 0x07b0f0,
            flatShading: true,
            side: THREE.DoubleSide,
        });
    },
    /**
     * Transparent Building 
     * 
     * @returns 
     */
    transparentBuilding: function () {
        return new THREE.MeshPhysicalMaterial({
            color: 0x07b0f0,
            metalness: 0.5,
            roughness: 2.0,
            side: THREE.FrontSide,
            opacity: 0.20,
            envMapIntensity: 10,
            transparent: true,
            blending: THREE.AdditiveBlending,
            depthTest: true,
            depthWrite: false,
            premultipliedAlpha: true
        })
    },
    /**
     * Hight Light Building
     * @returns 
     */
    highlightBuilding: function () {
        return new THREE.MeshBasicMaterial(
            { color: 0x07b0f0 }
        )
    },
    /**
     * Solid Gray
     * 
     * @returns 
     */
    solidGrey: function () {
        return new THREE.MeshBasicMaterial({ color: 0x444444 })
    },
    /**
     * Solid Black
     * 
     * @returns 
     */
    solidBlack: function () {
        return new THREE.MeshBasicMaterial({ color: 0x000000 })
    },
    /**
     * Blue Glow Ligth
     * 
     * @returns 
     */
    blueGlowLight: function () {

        var glowTexture = new THREE.TextureLoader().load('assets/textures/glow.png');
        var glowAlphaTexture = new THREE.TextureLoader().load('assets/textures/glowAlpha.jpg');

        glowTexture.flipY = false;
        glowAlphaTexture.flipY = false;

        return new THREE.MeshLambertMaterial({
            map: glowTexture,
            alphaMap: glowAlphaTexture,
            blending: THREE.AdditiveBlending,
            premultipliedAlpha: true,
            transparent: true,
            opacity: 1.0,
            depthTest: true,
            depthWrite: false,
            wireframe: false,
            alphaTest: 0,
            emissive: 8.0,
            color: 0xffffff,
            side: THREE.DoubleSide
        });
    },
    /**
     * Solid Building Extras
     * 
     * @returns 
     */
    solidBuildingExtras: function () {
        return new THREE.MeshStandardMaterial({
            color: 0x07b0f0,
            wireframe: false,
            transparent: true,
            opacity: 0.4,
            flatShading: true,
        })
    },
    /**
     * Solid Building Extras Line
     * 
     * @returns 
     */
    solidBuildingExtrasLine: function () {
        return new THREE.LineBasicMaterial({
            color: 0x07b0f0,
            transparent: true,
            opacity: 0.5,
        })
    },
    solidBuildingExtrasLineHard: function () {
        return new THREE.MeshStandardMaterial({
            color: 0x07b0f0,
            emissive: 0x07b0f0, 
            emissiveIntensity: 5.0
        })
    },      
    solidBuildingExtrasLineEmmisive: function () {
        return new THREE.LineBasicMaterial({
            color: 0x07b0f0
        });
    },      
    solidBuilding: new THREE.MeshPhysicalMaterial({
        map: null,
        color: 0x07b0f0,
        metalness: 0,
        roughness: 0,
        opacity: 0.35,
        side: THREE.DoubleSide,
        transparent: true,
        envMapIntensity: 10,
        premultipliedAlpha: true,
    }),
    /**
     * Blue Wireframe 
     * 
     * @returns 
     */
    blueWireframe: function () {
        return new THREE.MeshStandardMaterial(
            {
                color: 0x07b0f0,
                transparent: true,
                opacity: 1.0,
                wireframe: true,
                emissive: 0x07b0f0, 
                emissiveIntensity: 5.0
            }
        )
    },

};