import * as THREE from 'three';
import { MathUtils } from 'three/src/math/MathUtils.js';
import { Border } from './border.js';
import { Materials } from './materials.js';
import { Particles } from './particles.js';
import { Clouds } from './clouds.js';

export class StageBusinessPartners {

    /**
     * Becenter
     * 
     * @param {THREE.Scene} scene 
     * @param {THREE.Camear} camera 
     * @param {THREE.LoadingManager} loadingManager 
     * @returns 
     */

    constructor(scene, camera, loadingManager) {
        var self = this;

        this.name = 'StageBusinessPartners';

        this._scene = scene;
        this._camera = camera;

        // The main clock
        this._clock = new THREE.Clock();

        // Stage container
        this.group = new THREE.Group();

        // Add stage container to main scene
        this._scene.add(this.group);

        // Set stage position in local coordinates
        //this.group.position.set(-50.0535, 0, -616.598);
        this.group.position.set(-450.0535, 0, -516.598);

        //this.group.rotation.y = 0.5;

        // Swaps to calculate distances
        this._vectorSource = new THREE.Vector3(0, 0, 0);
        this._vectorTarget = new THREE.Vector3(0, 0, 0);

        // By default stage is not playing
        this.isPlaying = false;

        return new Promise((resolve, reject) => {
            self.buildProps();
            resolve(self);
        });

    }

    /**
     * Build all props
     * 
     * @return void
     */
    buildProps() {

        // @Build: Outer circle [orange]
        var outerCircle = new THREE.Mesh(
            new THREE.TorusGeometry(4.0, 0.01, 8, 100),
            new THREE.MeshStandardMaterial({ color: 0xfe4f0e, emissive: 0x410c0c, emissiveIntensity: 2.3 })
        );

        outerCircle.position.y = 0;
        outerCircle.position.z = 0;
        outerCircle.rotation.x = Math.PI / 2;
        outerCircle.scale.set(9, 9, 9);

        this.group.add(outerCircle);

        // @Build: Inner circle [white] 
        var borderCircle = new THREE.Line( // Mesh 
            new Border([
                [-1.0129451751708984, -0.4703410267829895, 0.0],
                [-0.6455241441726685, -0.021300487220287323, 0.0],
                [-0.22777947783470154, 0.5569054484367371, 0.0],
                [0.06616421043872833, 0.8079800605773926, 0.0],
                [0.46435001492500305, 0.8755093812942505, 0.0],
                [0.7038109302520752, 0.5232619047164917, 0.0],
                [0.9485481977462769, 0.4300677478313446, 0.0],
                [1.1423969268798828, -0.3365743160247803, 0.0],
                [0.8587403893470764, -0.6291802525520325, 0.0],
                [0.6767423152923584, -0.9429664611816406, 0.0],
                [0.2351183295249939, -0.8393186330795288, 0.0],
                [-0.5901067852973938, -0.821402907371521, 0.0],
                [-0.8604170083999634, -0.8694314956665039, 0.0],
                [-0.8722636103630066, -0.645824670791626, 0.0],
            ], .05, 50),
            new THREE.MeshStandardMaterial({ color: 0x07b0f0 /* , emissive: 0x07b0f0, emissiveIntensity: 5.0  */ })
            // new THREE.LineBasicMaterial({ color: 0x07b0f0, emissive: 0x07b0f0, emissiveIntensity: 5.0 })
        );

        borderCircle.position.set(0, 0, 0);
        this.group.add(borderCircle);

        // Particles
        this.particles = new Particles(this._scene, this._camera, 60);
        this.particles.group.position.set(0, 0, 0);
        this.group.add(this.particles.group);

        // Clouds
        this.clouds = new Clouds(this._scene, this._camera);
        this.group.add(this.clouds.group);
    }

    /**
     * Parse all elements in GLB file and insert into group
     * 
     * @param {THREE.Object3D} object 
     * @returns Promise 
     */
    parse(object) {
        var self = this;

        return new Promise(
            (resolve, reject) => {

                object.scene.traverse(
                    function (item) {

                        if (!item.isMesh)
                            return;

                        var child = item.clone();

                        switch (child.name) {
                            case 'Logos_node':

                                var mapTexture = new THREE.TextureLoader().load('assets/textures/texture_logos.jpg');
                                mapTexture.flipY = false;

                                child.material = new THREE.MeshBasicMaterial({
                                        map: mapTexture,
                                });
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);

                            break;

                            case 'Building_TopLogo_node':

                                var wireframeChild = new THREE.LineSegments(
                                    new THREE.EdgesGeometry(child.geometry),
                                    Materials.solidBuildingExtrasLineEmmisive()
                                );
                                //wireframeChild.layers.set(1);
                                wireframeChild.scale.set(0.3, 0.3, 0.3);
                                self.group.add(wireframeChild);

                            break;
                            case 'Building_TopLight_node':

                                child.material = Materials.blueGlowLight();
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);

                                console.log('Light')

                                break;                            
                            case 'Building_node':
                                child.material = Materials.solidBuildingFlat();
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);

                                var wireframeChild = new THREE.LineSegments(
                                    new THREE.EdgesGeometry(child.geometry),
                                    Materials.wireframeSolidBlue()
                                );
                                wireframeChild.scale.set(0.3, 0.3, 0.3);
                                self.group.add(wireframeChild);

                                break;

                            case 'Dome_node':
                                child.material = Materials.blueGlowLight();
                                child.material.needsUpdate = true;
                                //child.position.z -= 1.5;
                                child.scale.set(0.3, 0.15, 0.3);
                                self.group.add(child);

                                // Orange outter circle
                                var TorusMesh = new THREE.Mesh(
                                    new THREE.TorusGeometry(31.5, 0.02, 8, 100),
                                    new THREE.MeshBasicMaterial({ color: 0xffffff })
                                );

                                TorusMesh.position.y = 0;
                                TorusMesh.position.z = 0;
                                TorusMesh.rotation.x = Math.PI / 2;

                                self.group.add(TorusMesh);

                                break;                            
                            case 'Extras_node':
                                child.material = Materials.solidBuildingExtras();
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);

                                var wireframeChild = new THREE.LineSegments(
                                    new THREE.EdgesGeometry(child.geometry),
                                    Materials.solidBuildingExtrasLine(),
                                );
                                wireframeChild.scale.set(0.3, 0.3, 0.3);
                                self.group.add(wireframeChild);

                                break;

                            default:
                                var Mesh = new THREE.Mesh(
                                    child.geometry,
                                    Materials.blueWireframe()
                                );
                                Mesh.scale.set(0.3, 0.3, 0.3);
                                self.group.add(Mesh);
                        }


                        resolve(this);
                    }
                );
            }
        );
    }


    /**
     * Return if object is near visible to the camera.
     * 
     * @returns bool
     */
    hasToUpdate() {
        this._vectorTarget.set(
            this._camera.position.x,
            this._camera.position.y,
            this._camera.position.z
        );
        var distance = this.group.position.distanceTo(this._vectorTarget);

        this.globalOpacity = MathUtils.smoothstep(distance, 70, 200);

        return distance < 140;
    }

    /**
     * Play all secondary animations
     * 
     * @returns void
     */
    resume() {
        if (this.isPlaying)
            return;
        this.particles.resume();
    }

    /**
     * Puase all secondary animations
     * 
     * @returns void
     */
    pause() {
        if (this.isPlaying == false)
            return;
        this.particles.pause();
    }

    /**
     * Update loop
     * 
     * @returns void
     */
    update() {

        // Update secondary animations if elements are near visible
        if (this.hasToUpdate()) {
            this.render();

            // Play secondary animations
            this.resume();
            this.isPlaying = true;
        } else {

            // Pause secondary animations
            this.pause();
            this.isPlaying = false;
        }

        // Call to render all primary elements
        this.renderAll();
    }

    /**
     * Render only visible elements
     * 
     * @returns void
     */
    render() {
        var time = this._clock.getDelta();

        this.clouds.update(time);
        this.particles.update(time, this.globalOpacity);

        // Custom code

    }

    /**
     * Render only primary elements
     * 
     * @returns void
     */
    renderAll() {
        this.particles.renderAll(this.globalOpacity);
    }



}