import * as THREE from 'three';
import { MathUtils } from 'three/src/math/MathUtils.js';
import { Border } from './border.js';
import { Materials } from './materials.js';
import { Particles } from './particles.js';
import { Clouds } from './clouds.js';

export class StageBecenter {

    /**
     * Becenter
     * 
     * @param {THREE.Scene} scene 
     * @param {THREE.Camear} camera 
     * @param {THREE.LoadingManager} loadingManager 
     * @returns 
     */

    constructor(scene, camera, loadingManager) {
        var self = this;

        this.name = 'StageBecenter';

        this._scene = scene;
        this._camera = camera;

        // The main clock
        this._clock = new THREE.Clock();

        // Stage container
        this.group = new THREE.Group();

        // Add stage container to main scene
        this._scene.add(this.group);

        // Set stage position in local coordinates
        this.group.position.set(-50.0535, 0, -616.598);
        //this.group.position.set(150.0535, 0, 199.965);
        this.group.rotation.y = MathUtils.degToRad(180);

        //this.group.rotation.y = 0.5;

        // Swaps to calculate distances
        this._vectorSource = new THREE.Vector3(0, 0, 0);
        this._vectorTarget = new THREE.Vector3(0, 0, 0);

        // By default stage is not playing
        this.isPlaying = false;

        return new Promise((resolve, reject) => {
            self.buildProps();
            resolve(self);
        });

    }

    /**
     * Build all props
     * 
     * @return void
     */
    buildProps() {

        // @Build: Outer circle [orange]
        var outerCircle = new THREE.Mesh(
            new THREE.TorusGeometry(4.0, 0.01, 8, 100),
            new THREE.MeshStandardMaterial({ color: 0xfe4f0e, emissive: 0x410c0c, emissiveIntensity: 2.3 })
        );

        outerCircle.position.y = 0;
        outerCircle.position.z = 0;
        outerCircle.rotation.x = Math.PI / 2;
        outerCircle.scale.set(9, 9, 9);

        this.group.add(outerCircle);

        // @Build: Inner circle [white] 
        var borderCircle = new THREE.Line( // Mesh 
            new Border([
                [-0.8329869508743286, 0.16468530893325806, 0.0] ,
                [-0.7733519673347473, 0.5355117917060852, 0.0] ,
                [-0.5300734639167786, 0.5722317099571228, 0.0] ,
                [-0.014548172242939472, 0.9738132953643799, 0.0] ,
                [0.6902794241905212, 0.3449816405773163, 0.0] ,
                [0.9736351370811462, 0.2788563370704651, 0.0] ,
                [1.0174578428268433, -0.23277072608470917, 0.0] ,
                [0.9679290056228638, -0.6413788199424744, 0.0] ,
                [0.5029596090316772, -0.8580625653266907, 0.0] ,
                [0.15013714134693146, -0.7701388597488403, 0.0] ,
                [-0.06352664530277252, -1.2036738395690918, 0.0] ,
                [-0.42298001050949097, -1.0454206466674805, 0.0] ,
                [-0.7529171705245972, -0.8332948088645935, 0.0] ,
                [-1.017262578010559, -0.996553897857666, 0.0] ,
                [-1.2534726858139038, -0.9054227471351624, 0.0] ,
                [-1.132931113243103, -0.677283525466919, 0.0] ,
                [-1.195202350616455, -0.32627469301223755, 0.0] ,
            ], .05, 50),
            new THREE.MeshStandardMaterial({ color: 0x07b0f0 /* , emissive: 0x07b0f0, emissiveIntensity: 5.0  */ })
            // new THREE.LineBasicMaterial({ color: 0x07b0f0, emissive: 0x07b0f0, emissiveIntensity: 5.0 })
        );

        borderCircle.position.set(0, 0, 0);
        this.group.add(borderCircle);

        // Particles
        this.particles = new Particles(this._scene, this._camera, 60);
        this.particles.group.position.set(0, 0, 0);
        this.group.add(this.particles.group);

        // Clouds
        this.clouds = new Clouds(this._scene, this._camera);
        this.group.add(this.clouds.group);
    }

    /**
     * Parse all elements in GLB file and insert into group
     * 
     * @param {THREE.Object3D} object 
     * @returns Promise 
     */
    parse(object) {
        var self = this;

        return new Promise(
            (resolve, reject) => {

                object.scene.traverse(
                    function (item) {

                        if (!item.isMesh)
                            return;

                        var child = item.clone();


                        switch (child.name) {
                            case 'Building_Logo_node':

                                var wireframeChild = new THREE.LineSegments(
                                    new THREE.EdgesGeometry(child.geometry),
                                    Materials.solidBuildingExtrasLineEmmisive()
                                );
                                wireframeChild.scale.set(0.3, 0.3, 0.3);
                                self.group.add(wireframeChild);

                            break;                            
                            case 'Building_Floor_node':
                                child.material = Materials.solidBlue();
                                child.material.transparent = true;
                                child.material.opacity = 1.0;
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);
                                break;
                            
                            case 'Building_node':
                                child.material = new THREE.MeshPhysicalMaterial({
                                    color: 0x07b0f0,
                                    metalness: 0.5,
                                    roughness: 2.0,
                                    side: THREE.FrontSide,
                                    opacity: 0.20,
                                    envMapIntensity: 10,
                                    transparent: true,
                                    blending: THREE.AdditiveBlending,
                                    depthTest: true,
                                    depthWrite: false,
                                    //premultipliedAlpha: true
                                });
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);

                                var wireframeChild = new THREE.LineSegments(
                                    new THREE.EdgesGeometry(child.geometry),
                                    Materials.wireframeSolidBlue()
                                );
                                wireframeChild.scale.set(0.3, 0.3, 0.3);
                                self.group.add(wireframeChild);

                                break;

                            case 'Dome_node':
                                child.material = Materials.blueGlowLight();
                                child.material.needsUpdate = true;
                                //child.position.z -= 1.5;
                                child.scale.set(0.3, 0.15, 0.3);
                                self.group.add(child);

                                // Orange outter circle
                                var TorusMesh = new THREE.Mesh(
                                    new THREE.TorusGeometry(31.5, 0.02, 8, 100),
                                    new THREE.MeshBasicMaterial({ color: 0xffffff })
                                );

                                TorusMesh.position.y = 0;
                                TorusMesh.position.z = 0;
                                TorusMesh.rotation.x = Math.PI / 2;

                                self.group.add(TorusMesh);

                                break;                            
                            case 'Extras_node':
                                child.material = Materials.solidBuildingExtras();
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);

                                var wireframeChild = new THREE.LineSegments(
                                    new THREE.EdgesGeometry(child.geometry),
                                    Materials.solidBuildingExtrasLine(),
                                );
                                wireframeChild.scale.set(0.3, 0.3, 0.3);
                                self.group.add(wireframeChild);

                                break;                            

                                case 'Projector_Light_node':

                                    var lightTexture = new THREE.TextureLoader().load('assets/textures/light.png');
                                    lightTexture.flipY = false;

                                    child.material = new THREE.MeshBasicMaterial({
                                        color: 0x07b0f0,
                                        transparent: true,
                                        opacity: 0.8,
                                        depthTest: true,
                                        depthWrite: false,                            
                                        alphaMap: lightTexture
                                    });
                
                                    child.material.needsUpdate = true;
                                    child.scale.set(0.3, 0.3, 0.3);
                                    self.group.add(child);        
        
                                    self.screenLight = child;

                                    break;                                

                                case 'Projector_Plane_node':
                                
                                    self.videoScreenBecenter = document.getElementById('screen-02');
                                    
                                    const videoTextureCircular = new THREE.VideoTexture(self.videoScreenBecenter);
                                    videoTextureCircular.format = THREE.RGBFormat;
                                    videoTextureCircular.flipY = false;
    
                                    child.material = new THREE.MeshBasicMaterial(
                                        {
                                            map: videoTextureCircular,
                                            side: THREE.DoubleSide,
                                            transparent: true,
                                            opacity: 0.8
                                        }
                                    );
                                    child.material.needsUpdate = true;
                                    child.scale.set(0.3, 0.3, 0.3);
                                    self.group.add(child);
    
                                    self.screenPlaneLight = child;

                                    break;
                            default:
                                var Mesh = new THREE.Mesh(
                                    child.geometry,
                                    Materials.blueWireframe()
                                );
                                Mesh.scale.set(0.3, 0.3, 0.3);
                                self.group.add(Mesh);
                        }


                        resolve(this);
                    }
                );
            }
        );
    }


    /**
     * Return if object is near visible to the camera.
     * 
     * @returns bool
     */
    hasToUpdate() {
        this._vectorTarget.set(
            this._camera.position.x,
            this._camera.position.y,
            this._camera.position.z
        );
        var distance = this.group.position.distanceTo(this._vectorTarget);

        this.globalOpacity = MathUtils.smoothstep(distance, 70, 200);

        return distance < 140;
    }

    /**
     * Play all secondary animations
     * 
     * @returns void
     */
    resume() {
        if (this.isPlaying)
            return;
    
        this.particles.resume();
        
        if( this.videoScreenBecenter )
            this.videoScreenBecenter.play();
    }

    /**
     * Puase all secondary animations
     * 
     * @returns void
     */
    pause() {
        if (this.isPlaying == false)
            return;
        this.particles.pause();

        if( this.videoScreenBecenter )
            this.videoScreenBecenter.pause();
    }

    /**
     * Update loop
     * 
     * @returns void
     */
    update() {

        // Update secondary animations if elements are near visible
        if (this.hasToUpdate()) {
            this.render();

            // Play secondary animations
            this.resume();
            this.isPlaying = true;
        } else {

            // Pause secondary animations
            this.pause();
            this.isPlaying = false;
        }

        // Call to render all primary elements
        this.renderAll();
    }

    /**
     * Render only visible elements
     * 
     * @returns void
     */
    render() {
        var time = this._clock.getDelta();
 
        this.clouds.update(time);
        this.particles.update(time, this.globalOpacity);

        // Custom code
        var timeTWO = new Date().getTime();

        this.screenLight.material.opacity = 1.0 + Math.sin(timeTWO * 0.1) - 0.8;

    }

    /**
     * Render only primary elements
     * 
     * @returns void
     */
    renderAll() {
        this.particles.renderAll(this.globalOpacity);
    }



}