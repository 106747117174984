import * as THREE from 'three';
import { MathUtils } from 'three/src/math/MathUtils.js';
import { Border } from './border.js';
import { Materials } from './materials.js';
import { Particles } from './particles.js';
import { Clouds } from './clouds.js';

// THREE.js Loaders
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

export class StagePortfolio {

    /**
     * Portfolio
     * 
     * @param {THREE.Scene} scene 
     * @param {THREE.Camear} camera 
     * @param {THREE.LoadingManager} loadingManager 
     * @returns 
     */

    constructor(scene, camera, loadingManager) {
        var self = this;

        this.name = 'StagePortfolio';

        this._scene = scene;
        this._camera = camera;

        // The main clock
        this._clock = new THREE.Clock();

        // Stage container
        this.group = new THREE.Group();

        // Add stage container to main scene
        this._scene.add(this.group);

        // Set stage position in local coordinates
        this.group.position.set(-227.9, 0, 180.2);

        // Swaps to calculate distances
        this._vectorSource = new THREE.Vector3(0, 0, 0);
        this._vectorTarget = new THREE.Vector3(0, 0, 0);

        // By default stage is not playing
        this.isPlaying = false;

        return new Promise((resolve, reject) => {
            self.buildProps();
            resolve(self);
        });

    }

    /**
     * Build all props
     * 
     * @return void
     */
    buildProps() {

        // @Build: Outer circle [orange]
        var outerCircle = new THREE.Mesh(
            new THREE.TorusGeometry(4.0, 0.01, 8, 100),
            new THREE.MeshStandardMaterial({ color: 0xfe4f0e, emissive: 0x410c0c, emissiveIntensity: 2.3 })
        );

        outerCircle.position.y = 0;
        outerCircle.position.z = 0;
        outerCircle.rotation.x = Math.PI / 2;
        outerCircle.scale.set(9, 9, 9);

        this.group.add(outerCircle);

        // @Build: Inner circle [white] 
        var borderCircle = new THREE.Line( // Mesh 
            new Border([
                [-1.335460901260376, -0.30228349566459656, 0.0],
                [-1.2583836317062378, 0.005160391330718994, 0.0],
                [-0.9193323254585266, 0.12617111206054688, 0.0],
                [-0.7704745531082153, 0.6985131502151489, 0.0],
                [-0.33110421895980835, 0.8342922925949097, 0.0],
                [-0.07503194361925125, 0.8977054357528687, 0.0],
                [0.2895680069923401, 1.1651287078857422, 0.0],
                [0.7929601669311523, 0.9504516124725342, 0.0],
                [1.1769464015960693, 0.22118306159973145, 0.0],
                [0.8933994174003601, -0.183353990316391, 0.0],
                [0.7916067838668823, -0.7162840962409973, 0.0],
                [0.3015039265155792, -0.9183970093727112, 0.0],
                [-0.1474553644657135, -1.302283525466919, 0.0],
                [-0.5267057418823242, -1.1682934761047363, 0.0],
                [-0.5596123933792114, -0.800086498260498, 0.0],
                [-0.9973168969154358, -0.7857940793037415, 0.0],
                [-1.143968105316162, -0.49684157967567444, 0.0],
            ], .05, 50),
            new THREE.MeshStandardMaterial({ color: 0x07b0f0 /* , emissive: 0x07b0f0, emissiveIntensity: 5.0  */ })
            // new THREE.LineBasicMaterial({ color: 0x07b0f0, emissive: 0x07b0f0, emissiveIntensity: 5.0 })
        );

        borderCircle.position.set(0, 0, 0);
        this.group.add(borderCircle);

        // Particles
        this.particles = new Particles(this._scene, this._camera, 60);
        this.particles.group.position.set(-5.4, 0, 3.15);
        this.group.add(this.particles.group);

        // Clouds
        this.clouds = new Clouds(this._scene, this._camera);
        this.group.add(this.clouds.group);
    }

    /**
     * Parse all elements in GLB file and insert into group
     * 
     * @param {THREE.Object3D} object 
     * @returns Promise 
     */
    parse(object) {
        var self = this;

        return new Promise(
            (resolve, reject) => {

                object.scene.traverse(
                    function (item) {

                        if( !item.isMesh )
                            return;

                        var child = item.clone();

                        switch (child.name) {

                            case 'Base_Line_node': 
                                child.material = Materials.solidBlue();
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);

                                break;
                            case 'Sphere_node': 
                                self.sphere = child;

                                child.material = self.buildTwistMaterial( 2.0 ); //Materials.blueWireframe();
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);

                                break;
                            case 'Base_Top_node': 
                                self.sphereBase = child;

                                child.material = Materials.blueWireframe();
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);

                                break;
                            case 'Dome_node': 
                                child.material = Materials.blueGlowLight();
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.15, 0.3);
                                self.group.add(child);

                                // Orange outter circle
                                var TorusMesh = new THREE.Mesh(
                                    new THREE.TorusGeometry(31.5, 0.02, 8, 100),
                                    new THREE.MeshBasicMaterial({ color: 0xffffff })
                                );

                                TorusMesh.position.y = 0;
                                TorusMesh.position.z = 0;
                                TorusMesh.rotation.x = Math.PI / 2;

                                self.group.add(TorusMesh);

                                break;
                            case 'Base_node':
                                child.material = Materials.solidBuildingFlat();
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);

                                var wireframeChild = new THREE.LineSegments(
                                    new THREE.EdgesGeometry(child.geometry),
                                    Materials.wireframeSolidBlue()
                                );
                                //wireframeChild.layers.set(1);
                                wireframeChild.scale.set(0.3, 0.3, 0.3);
                                self.group.add(wireframeChild);

                                break;
                            case 'Base_Inner_node':
                                child.material = Materials.highlightBuilding();
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);

                                break;
                            case 'Extras_node': 
                                child.material = Materials.solidBuildingExtras();
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);

                                var wireframeChild = new THREE.LineSegments(
                                    new THREE.EdgesGeometry(child.geometry),
                                    Materials.solidBuildingExtrasLine(),
                                );
                                wireframeChild.scale.set(0.3, 0.3, 0.3);
                                self.group.add(wireframeChild);

                                break;
                        }

                        /*
                        var Mesh = new THREE.Mesh(
                            child.geometry,
                            Materials.blueWireframe()
                        );
                        Mesh.scale.set(0.3, 0.3, 0.3);
                        self.object.add(Mesh);          
                        */

                        resolve(this);
                    }
                );
            }
        );
    }

    buildTwistMaterial( amount ) {

        const material = new THREE.MeshNormalMaterial();

        material.onBeforeCompile = function ( shader ) {
            shader.uniforms.time = { value: 0 };

            shader.vertexShader = 'uniform float time;\n' + shader.vertexShader;
            shader.vertexShader = shader.vertexShader.replace(
                '#include <begin_vertex>',
                [
                    `float theta = sin( time + position.y ) / ${ amount.toFixed( 1 ) };`,
                    'float c = cos( theta );',
                    'float s = sin( theta );',
                    'mat3 m = mat3( c, 0, s, 0, 1, 0, -s, 0, c );',
                    'vec3 transformed = vec3( position ) * m;',
                    'vNormal = vNormal * m;'
                ].join( '\n' )
            );

            material.userData.shader = shader;
        };

        material.customProgramCacheKey = function () {
            return amount;
        };

        return material;
    }


    /**
     * Return if object is near visible to the camera.
     * 
     * @returns bool
     */
    hasToUpdate() {
        this._vectorTarget.set(
            this._camera.position.x,
            this._camera.position.y,
            this._camera.position.z
        );
        var distance = this.group.position.distanceTo(this._vectorTarget);

        this.globalOpacity = MathUtils.smoothstep(distance, 70, 120);

        return distance < 100;
    }

    /**
     * Play all secondary animations
     * 
     * @returns void
     */
    resume() {
        if (this.isPlaying)
            return;
        this.particles.resume();
    }

    /**
     * Puase all secondary animations
     * 
     * @returns void
     */
    pause() {
        if (this.isPlaying == false)
            return;
        this.particles.pause();
    }

    /**
     * Update loop
     * 
     * @returns void
     */
    update() {

        // Update secondary animations if elements are near visible
        if (this.hasToUpdate()) {
            this.render();

            // Play secondary animations
            this.resume();
            this.isPlaying = true;
        } else {

            // Pause secondary animations
            this.pause();
            this.isPlaying = false;
        }

        // Call to render all primary elements
        this.renderAll();
    }

    /**
     * Render only visible elements
     * 
     * @returns void
     */
    render() {
        var time = this._clock.getDelta();

        this.sphere.rotation.y -= 0.015;
        this.sphereBase.rotation.y += 0.02;

        const shader = this.sphere.material.userData.shader;
        if ( shader ) {
            shader.uniforms.time.value = performance.now() / 1000;
        }

        this.clouds.update(time);
        this.particles.update(time, this.globalOpacity);

    }

    /**
     * Render only primary elements
     * 
     * @returns void
     */
    renderAll() {
        this.particles.renderAll(this.globalOpacity);
    }



}