
// THREE.js
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

/*
import * as THREE from 'https://cdn.skypack.dev/three';
import { OrbitControls } from 'https://cdn.skypack.dev/three/examples/jsm/controls/OrbitControls.js';
*/

import { HotspotManager } from './panorama.hotspotManager.js';

export class Panorama {

    constructor() {
       
        this.currentSpot = 'main-entry';
        this.spots = [
            {
                id: 'main-entry',
                name: 'Bienvenido a <br/>BE CENTER',
                description: 'Oficinas principales de vanguardia con mobiliario inteligente y tecnología de punta para un ambiente de colaboración dinámico y móvil (cero cables).',
                type: 'room',
                renderURL: 'assets/images/tour/spot-01.jpg',
                rotation: -90,
                hotspots: [
                    {
                        name: 'Centro de <br/>capacitación',
                        label: 'Centro de capacitación',
                        description: 'Description',
                        position: {
                            x: -4.5,
                            y: 0,
                            z: -6
                        },
                        type: 'link',
                        link: 'centro-capacitaciones'
                    },
                    {
                        name: 'Oficinas de <br/>corporativas',
                        label: 'Oficinas corporativas',
                        description: 'Description',
                        position: {
                            x: 7,
                            y: 0,
                            z: -4
                        },
                        type: 'link',
                        link: 'oficinas-corporativas'
                    },
                    {
                        name: 'Centro de <br/>operaciones',
                        label: 'Centro de operaciones',
                        description: 'Description',
                        position: {
                            x: 0,
                            y: 0,
                            z: -10
                        },
                        type: 'link',
                        link: 'centro-operaciones'
                    },
                    {
                        name: 'Centro de <br/>innovación',
                        label: 'Centro de innovación e investigación',
                        description: 'Description',
                        position: {
                            x: 5,
                            y: -0.2,
                            z: -0.5
                        },
                        type: 'link',
                        link: 'centro-innovacion'
                    }
                ]
            },
            {
                id: 'centro-innovacion',
                name: 'Centro de innovación <br/>e investigación',
                description: 'Demostración de tendencias tecnológicas, inmersión en realidad virtual y aumentada, laboratorios de homologación e integración de tecnología y desarrollo de nuevos negocios.',
                type: 'room',
                renderURL: 'assets/images/tour/spot-05.jpg',
                rotation: -90,
                hotspots: [
                    {
                        name: 'Centro de capacitación',
                        label: 'Centro de capacitación',
                        description: 'Description',
                        position: {
                            x: 5,
                            y: 0,
                            z: 3.5
                        },
                        type: 'link',
                        link: 'centro-capacitaciones'
                    },
                    {
                        name: 'Oficinas corporativas',
                        label: 'Oficinas corporativas',
                        description: 'Description',
                        position: {
                            x: -4,
                            y: 0,
                            z: 3
                        },
                        type: 'link',
                        link: 'oficinas-corporativas'
                    },
                    {
                        name: 'Centro de operaciones',
                        label: 'Centro de operaciones',
                        description: 'Description',
                        position: {
                            x: 3,
                            y: 0,
                            z: 6
                        },
                        type: 'link',
                        link: 'centro-operaciones'
                    },
                    // Info
                    {
                        name: 'SDWAN (Meraki y Fortinet)',
                        label: 'SDWAN (Meraki y Fortinet)',
                        description: '<h5>SDWAN (Meraki y Fortinet)</h5>Permite a las empresas construir una WAN de mayor rendimiento utilizando acceso a Internet de menor costo disponible comercialmente , lo que habilita a las empresas a reemplazar parcial o totalmente las tecnologías de conexión WAN privadas más caras, como MPLS  y simplificar su administración.',
                        position: {
                            x: 3,
                            y: 1,
                            z: -6
                        },
                        type: 'info',
                    },
                    {
                        name: 'SDACCESS (DNA Center)',
                        label: 'SDACCESS (DNA Center)',
                        description: '<h5>SDACCESS (DNA Center)</h5>Evolución de las redes bases en intención (IBN) que proporciona un control de accesos automatizado, aplica las políticas correctas e introduce el nivel adecuado de segmentación de usuarios, dispositivos y aplicaciones en todo el entorno de red.',
                        position: {
                            x: 3,
                            y: -2,
                            z: -6
                        },
                        type: 'info',
                    },
                    {
                        name: 'Hiperconvergenia (Hyperflex)',
                        label: 'Hiperconvergenia (Hyperflex)',
                        description: '<h5>Hiperconvergenia (Hyperflex)</h5>Sistemas de cómputo, almacenamiento, routing y switching, integrados en una misma solución para maximizar las inversiones y simplificar los modelos de gestión reemplazando las matrices de almacenamiento.',
                        position: {
                            x: -2,
                            y: 1,
                            z: -6
                        },
                        type: 'info',
                    },
                    {
                        name: 'Be Cloud',
                        label: 'Be Cloud',
                        description: '<h5>Be Cloud</h5>Nube de servicios hiperconvergente 100% mexicana diseñada para habilitar máquinas virtuales y respaldos empresariales en un entrono innovador, flexible y seguro con tecnologia de clase mundial.',
                        position: {
                            x: 0.35,
                            y: -0.5,
                            z: -6
                        },
                        type: 'info',
                    },
                    {
                        name: 'Meraki Sensors (IoT)',
                        label: 'Meraki Sensors (IoT)',
                        description: '<h5>Meraki Sensors (IoT)</h5>Sensores de humedad, temperatura y apertura de puertas fáciles de implementar para proteger la infraestructura de TI crítica con una plataforma de administración centralizada que brinda visibilidad y alertas en tiempo real.',
                        position: {
                            x: -2,
                            y: -2,
                            z: -6
                        },
                        type: 'info',
                    },
                    /*
                    {
                        name: 'Centro de operaciones',
                        label: 'Centro de operaciones',
                        description: 'Description',
                        position: {
                            x: 0,
                            y: 0,
                            z: -5
                        },
                        type: 'link',
                        link: 'centro-operaciones'
                    }
                    */
                ]
            },
            {
                id: 'oficinas-corporativas',
                name: 'Oficinas <br/>corporativas',
                description: 'Oficinas principales de vanguardia con mobiliario Inteligente y tecnología de punta para un ambiente de colaboración dinámico y móvil (cero cables).',
                type: 'room',
                renderURL: 'assets/images/tour/spot-04.jpg',
                rotation: 90,
                hotspots: [
                    {
                        name: 'War room',
                        label: 'War room',
                        description: 'Espacio para medición de KPI de las distintas áreas del negocio por medio de un BI (Tableau) para garantizar un correcto desempeno bajo un modelo basado en resultados por indicadores.',
                        position: {
                            x: 0,
                            y: -0.2,
                            z: 6
                        },
                        type: 'info'
                    },
                    {
                        name: 'Centro de capacitación',
                        label: 'Centro de capacitación',
                        description: 'Description',
                        position: {
                            x: -10,
                            y: 0,
                            z: 0
                        },
                        type: 'link',
                        link: 'centro-capacitaciones'
                    },
                    {
                        name: 'BE CENTER',
                        label: 'BE CENTER',
                        description: 'Description',
                        position: {
                            x: -5,
                            y: 0,
                            z: 3
                        },
                        type: 'link',
                        link: 'main-entry'
                    },
                    {
                        name: 'Centro de innovación',
                        label: 'Centro de innovación e investigación',
                        description: 'Description',
                        position: {
                            x: -3,
                            y: -0.2,
                            z: 6
                        },
                        type: 'link',
                        link: 'centro-innovacion'
                    },
                    {
                        name: 'Centro de operaciones',
                        label: 'Centro de operaciones',
                        description: 'Description',
                        position: {
                            x: 0,
                            y: 0,
                            z: -5
                        },
                        type: 'link',
                        link: 'centro-operaciones'
                    }
                ]
            },
            {
                id: 'centro-capacitaciones',
                name: 'Centro de <br/>capacitación',
                description: 'Talleres para formación de ingeniería CCNA y NSE4-5. Alianza con el MIT para cursos de Transformación Digital oara ejecutivos. Pláticas de tendencias tecnológicas de fabricantes líderes del mercado.',
                type: 'room',
                renderURL: 'assets/images/tour/spot-02.jpg',
                rotation: -90,
                hotspots: [
                    {
                        name: 'BE CENTER',
                        label: 'BE CENTER',
                        description: 'Description',
                        position: {
                            x: 2,
                            y: 0,
                            z: 7
                        },
                        type: 'link',
                        link: 'main-entry'
                    },
                    {
                        name: 'Centro de operaciones',
                        label: 'Centro de operaciones',
                        description: 'Description',
                        position: {
                            x: 2.5,
                            y: 0,
                            z: -2
                        },
                        type: 'link',
                        link: 'centro-operaciones'
                    },
                    {
                        name: 'Oficinas corporativas',
                        label: 'Oficinas corporativas',
                        description: 'Description',
                        position: {
                            x: 6,
                            y: 0,
                            z: 1.5
                        },
                        type: 'link',
                        link: 'oficinas-corporativas'
                    },
                    {
                        name: 'Centro de innovación',
                        label: 'Centro de innovación e investigación',
                        description: 'Description',
                        position: {
                            x: 2,
                            y: 0,
                            z: 2
                        },
                        type: 'link',
                        link: 'centro-innovacion'
                    },
                    // Info        
                    {
                        label: 'WiFi 6',
                        description: '<h5>WiFi 6</h5>Soluciones de conectividad inalámbrica altamente confiables para transmisión de video en 4K, audio y datos pueden ayudar a satisfacer las demandas empresariales y las expectativas de los usuarios de hoy en día. Mayor velocidad, alcance y menor consumo energetico.',
                        position: {
                            x: 6.5,
                            y: 3.5,
                            z: 1.5
                        },
                        type: 'info'
                    },
                    {
                        label: 'Cloud PBX (Webex Calling)',
                        description: '<h5>Cloud PBX (Webex Calling)</h5>Cloud PBX es un sistema de llamadas en la nube con grado empresarial que opera bajo un modelo de suscripción flexible con múltiples beneficios.',
                        position: {
                            x: 5.05,
                            y: -3.5,
                            z: -4.3
                        },
                        type: 'info'
                    },
                    {
                        label: 'Pantallas Inteligentes (Webex Board)',
                        description: '<h5>Pantallas Inteligentes (Webex Board)</h5>Dispositivos inteligentes para presentar de forma inalámbrica, hacer una videoconferencia, dibujar o escribir en el pizarrón digital e incluso hacer anotaciones sobre el contenido que se está compartiendo.',
                        position: {
                            x: 1.05,
                            y: -0.5,
                            z: -4.3
                        },
                        type: 'info'
                    }
                ]
            },
            {
                id: 'centro-operaciones',
                name: 'Centro de <br/>operaciones',
                description: 'Herramientas de monitoreo proactivo especializadas en servicios administrados. Ingeniería con certificaciones especializadas. Procesos basados en ITIL/ISO27001.',
                type: 'room',
                renderURL: 'assets/images/tour/spot-03.jpg',
                rotation: -90,
                hotspots: [
                    {
                        name: 'Centro de capacitaciones',
                        label: 'Centro de capacitaciones',
                        description: 'Description',
                        position: {
                            x: -7,
                            y: 0,
                            z: 1.5
                        },
                        type: 'link',
                        link: 'centro-capacitaciones'
                    },
                    {
                        name: 'Oficinas corporativas',
                        label: 'Oficinas corporativas',
                        description: 'Description',
                        position: {
                            x: -5,
                            y: 0,
                            z: 6.5
                        },
                        type: 'link',
                        link: 'oficinas-corporativas'
                    },
                    //
                    {
                        name: 'Videowall Interactivo',
                        label: 'Videowall Interactivo',
                        description: '<h5>Videowall interactivo</h5>Solución  para visualizar el estado de las alertas, requerimientos e incidentes de nuestra operación del día a día, integrada con nuestras plataformas de software para servicios administrados.',
                        position: {
                            x: 2,
                            y: 0,
                            z: -6.5
                        },
                        type: 'info',
                    },
                    {
                        name: 'Videovigilancia',
                        label: 'Videovigilancia',
                        description: '<h5>Videovigilancia</h5>Solución de videovigilancia sumamente simple de implementar, configurar y administrar que elimina el uso del tradicional NVR ya que cuenta con almacenamiento de estado sólido integrado y analíticos de video para reconocimiento de placas, mapas de calor y detección de objetos.',
                        position: {
                            x: -3,
                            y: 0,
                            z: -6.5
                        },
                        type: 'info',
                    },
                ]
            },
    
        ];

        this.preloadDOM = document.getElementById('virtualTourPreload');
        this.hotspotsContainer = document.querySelector('.ui-hotspots');

        this.scene = new THREE.Scene();

        this.camera = new THREE.PerspectiveCamera(76, window.innerWidth / window.innerHeight, 1, 100);
        this.camera.position.z = 0.01;

        this.renderer = new THREE.WebGLRenderer({ alpha: true });
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setClearColor(0x000000, 0);

        this.rendererContainer = document.querySelector('#virtualTour');
        this.rendererContainer.appendChild(this.renderer.domElement);

        console.log('Load');

        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enableZoom = false;
        this.controls.zoomSpeed = 15;
        this.controls.enablePan = true;
        this.controls.enableDamping = true;
        this.controls.rotateSpeed = - 0.5;


        const geometrySphere = new THREE.SphereBufferGeometry(100, 60, 40);
        geometrySphere.scale(- 1, 1, 1);

        /*
        const texture = new THREE.TextureLoader().load('assets/images/tour/spot-01.jpg');
        */

        const materialTexture = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 });
        this.panoramaMesh = new THREE.Mesh(geometrySphere, materialTexture);
        this.scene.add(this.panoramaMesh);

        this.hotspotManager = new HotspotManager(this.scene, this.camera, this.hotspotsContainer);

        window.addEventListener('resize', this.onWindowResize.bind(this));

        this.controls.addEventListener('change', this.change.bind(this));
        this.controls.addEventListener('start', this.startDrag.bind(this));
        this.controls.addEventListener('end', this.endDrag.bind(this));


        document.getElementById('panoramaPrev').addEventListener('click', this.gotoPrev.bind(this));
        document.getElementById('panoramaNext').addEventListener('click', this.gotoNext.bind(this));
        
        //document.getElementById('panoramaOpen').addEventListener('click', this.open.bind(this));
        document.getElementById('panoramaClose').addEventListener('click', this.close.bind(this));
    }

    open() {
        return new Promise(
            (resolve, reject) => {

                this.goto('main-entry', false).then(
                    () => {
                        document.querySelector('.ui-panorama').style.visibility = 'visible';
                        resolve();
                    }
                );

                this.isPlaying = true;
                this.animate();
        
            }
        );
    }

    close() {
        try {
            window.Beprime.resume();
        } catch {

        }

        document.querySelector('.ui-panorama').style.visibility = 'hidden';
        setTimeout(() => {
            this.isPlaying = false;
        }, 1200);
    }

    change() {
        //this.hotspotManager.update();
    }

    startDrag() {
        document.body.classList.remove('js--animating');
        document.body.classList.add('js--is-dragging');
    }

    endDrag() {
        setTimeout(() => {
            document.body.classList.add('js--animating');
            document.body.classList.remove('js--is-dragging');
        }, 500);
    }

    onWindowResize(event) {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(event.target.innerWidth, event.target.innerHeight);
        this.hotspotManager.update();
    }

    getCurrentSpotIndex() {
        return this.spots.findIndex(
            (spot) => {
                return spot.id === this.currentSpot;
            }
        );
    }

    gotoNext(event) {
        event.preventDefault();

        var nextSpotId;
        try {
            nextSpotId = this.spots[this.getCurrentSpotIndex() + 1]['id'];
        } catch {
            nextSpotId = this.spots[0]['id'];
        } finally {
            this.goto(nextSpotId);
        }
    }

    gotoPrev(event) {
        event.preventDefault();

        var nextSpotId;
        try {
            nextSpotId = this.spots[this.getCurrentSpotIndex() - 1]['id'];
        } catch {
            nextSpotId = this.spots[this.spots.length - 1]['id'];
        } finally {
            this.goto(nextSpotId);
        }
    }

    showPreloader() {
        //        document.getElementById('stripper').classList.add('show');
        document.getElementById('stripper').classList.remove('close');
    }

    hidePreloader() {
        //      document.getElementById('stripper').classList.remove('show');
        document.getElementById('stripper').classList.add('close');
    }

    goto(spotId, show = true) {

        return new Promise(
            (resolve, reject) => {

                //if (show)
                this.showPreloader();

                var panorama = this.spots.find(
                    (spot) => {
                        return spot.id === spotId;
                    }
                );

                this.currentSpot = spotId;

                // Show preload
                //this.preloadDOM.style.visibility = 'visible';
                //document.body.classList.add('js--panorama-loading');

                setTimeout(
                    () => {

                        // Delete all hotspots from hotspot manager
                        this.hotspotManager.reset();

                        // Insert hotspots on canvas and dom
                        panorama.hotspots.forEach(
                            (hotspot) => {
                                this.hotspotManager.add(hotspot);
                            }
                        );

                        this.hotspotManager.update();

                        this.loadSpot(panorama).then(
                            () => {

                                document.querySelector('#p').innerHTML = panorama.name;
                                document.querySelector('#t').innerHTML = panorama.description;

                                document.getElementById('panoramaName').setAttribute('data-room', (this.getCurrentSpotIndex() + 1) + '/' + this.spots.length);

                                //document.body.classList.remove('js--panorama-loading');
                                //this.preloadDOM.style.visibility = 'hidden';

                                document.querySelectorAll('.strip').forEach((item) => {
                                    item.style.animationPlayState = 'running';
                                });

                                this.hidePreloader();

                                resolve();

                            }
                        );

                    }
                    , 800);

            }
        );


    }

    loadSpot(spotData = {}) {
        var self = this;

        return new Promise(
            (resolve, reject) => {

                const loader = new THREE.TextureLoader();
                loader.load(
                    spotData.renderURL,
                    (textureMap) => {

                        self.panoramaMesh.material = new THREE.MeshBasicMaterial({
                            map: textureMap,
                            transparent: false,
                            opacity: 1
                        });

                        self.panoramaMesh.rotation.y = spotData.rotation * (Math.PI / 180);

                        setTimeout(() => {
                            resolve();
                        }, 1000);
                    }
                );

            }
        )

    }

    render() {
        this.renderer.render(this.scene, this.camera);
    }

    animate() {

        if (!this.isPlaying)
            return false;

        window.requestAnimationFrame(() => this.animate());
        this.controls.update();
        this.hotspotManager.update();
        this.render();
    }

}

