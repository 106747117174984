export const C_Style = {
    dark1: 0x02131D,
    dark2: 0x022547,
    dark3: 0x00103d,
    accent: 0x073A4E,
    white: 0x555555,
    concrete: 0x022547,

    fogNear: 1,
    fogFar: 1000,
    fogColor: 0x02131D
}