// Core
import { C_Style } from './constans.js';
import * as THREE from 'three';

export class Border {

    constructor(points, radius, scale) {
        for (var i = 0; i < points.length; i++) {
            var x = points[i][0] * scale;
            var y = points[i][1] * scale;
            var z = points[i][2] * scale;
            points[i] = new THREE.Vector3(x, z, -y);
        }

        var curve = new THREE.CatmullRomCurve3(points, true, 'centripetal', 0.5);

        return new THREE.TubeGeometry(curve, 600, radius, 10, true);
        //return new THREE.BufferGeometry().setFromPoints(curve.getPoints(100));
    }

}