import './assets/css/index.min.css';

// Constants
import { C_Style } from './constans.js';

// Audio
import { AudioEnviroment } from './audio.js';

// THREE.js
import * as THREE from 'three'

// THREE.js Dats
import * as dat from 'dat.gui'

// THREE.js Stats
import Stats from 'three/examples/jsm/libs/stats.module.js';

// THREE.js Controls
import { MapControls } from 'three/examples/jsm/controls/OrbitControls.js';
//import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';

// THREE.js Post-proccesing
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
/*
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
*/

// THREE.js Loaders
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

// Customs 
import { Plane } from './plane.js';
import { Materials } from './materials.js';
import { Roads } from './roads.js';
import { Path } from './path.js';

// Stages
import { StageAbout } from './stageAbout.js';
import { StagePortfolio } from './stagePortfolio.js';
import { StageSuccessStories } from './stageSuccessStories.js';
import { StageManagedServices } from './stageManagedServices.js';
import { StageBecenter } from './stageBecenter.js';
import { StageContactUs } from './stageContactUs.js';
import { StageEnergyServices } from './stageEnergyServices.js';
import { StageBusinessPartners } from './stageBusinessPartners.js';

import TWEEN from '@tweenjs/tween.js';

import { Cursor } from './cursor.js';

import { Panorama } from './panorama.js';

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

const audioEnviroment = new AudioEnviroment();

window.Panorama = new Panorama();

window.popupFormComponent = new Vue({
    el: '#popupForm',
    data: {
        img: 1,
        visible: false
    },
    created: function () {

    },
    methods: {
        show: function (id) {
            this.img = id;
            this.$el.classList.add('js--show');
        },
        close: function () {
            this.$el.classList.remove('js--show');
        },
        sendForm: function (event) {
            event.preventDefault();
            this.close();
        }
    }
});

window.popupComponent = new Vue({
    el: '#popup',
    data: {
        img: 1,
        visible: false
    },
    created: function () {

    },
    methods: {
        show: function (id) {
            this.img = id;
            this.$el.classList.add('js--show');
        },
        close: function () {
            this.$el.classList.remove('js--show');
            console.log(this);
        }
    }
});

window.contentApp = new Vue({
    el: '#content',
    data: {
        isExpanded: false,
        isPrime: false,
        section: 5,
        detail: null
    },
    created: function () {
        this.isExpanded = this.isMobile();
    },
    methods: {
        isMobile: function () {
            return screen.width < 760;
        },
        expandAction: function () {
            this.isExpanded = !this.isExpanded;

            if (this.isExpanded == false)
                this.detail = null;
        },
        show: function (section, expanded = false) {
            this.isExpanded = expanded;
            this.section = section;
        },
        hideComponent: function () {
            this.style.display = 'none';
        },
        showDetail: function (detail) {
            if (!this.isPrime && (detail == 'about/more' || detail == 'partner/fortinet' || detail == 'partner/cisco'))
                return true;

            this.isExpanded = true;
            this.detail = detail;
        },
        openTour: function () {
            window.Panorama.open().then(
                () => {
                    window.Beprime.pause();
                }
            );
        },
        displayPopup: function (i) {
            /*
            var popup = document.getElementById('popup');
            popup.classList.add('js--show');
            */
            window.popupComponent.show(i);
        }
    }
});

class Index {
    /**
     * Constructor
     */
    constructor() {
        //this._canvas = document.querySelector('canvas.webgl');

        this.stages = [];

        this.LIGHT_LAYER = 0;
        this.BLOOM_LAYER = 1;

        this.mouseCursor = {
            x: 0, y: 0
        };

        this.progressLoader = document.getElementById('loader');
        this.progressLoaderBar = document.getElementById('progress-bar');

        /**
         * Build main camera
         */
        this.buildCamera({
            near: 1,
            far: 1000,
            position: {
                x: -1,
                y: 1100,
                z: 1
            }
        });

        /**
         * Build main scene
         */
        this.buildScene();

        /**
         * Build renderer engine
         */
        this.buildRenderer();

        /**
         * Build post-processing
         */
        this.buildPostprocessing(
            {
                exposure: 1.0, // 1.0
                bloom: {
                    threshold: 0.21, // 0.21
                    strength: 0.9, // 0.9
                    radius: 1.0 // 1.0
                }
            }
        );

        /**
         * Build lights
         */
        this.buildLights();

        /**
         * Build controls
         */
        this.buildControls();

        /**
         * Build stats
         */
        // this.buildStats();

        /**
         * Bind event listeners
         */
        this.bindEventListener();

        /**
         * Set resize
         */
        //this.onWindowResize();

        /**
         * Load main scene
         */
        this.loadScene();


        // this.transformControl = new TransformControls(this._camera, this._renderer.domElement);
    }

    buildCamera(cameraSettings) {
        this._camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, cameraSettings.near, cameraSettings.far);
        this._camera.position.set(cameraSettings.position.x, cameraSettings.position.y, cameraSettings.position.z);
        /*
        this._camera.lookAt(cameraSettings.position.x, cameraSettings.position.y, cameraSettings.position.z);
        */
    }

    buildScene() {
        this._scene = new THREE.Scene();
        this._scene.background = new THREE.Color(C_Style.dark2);
        /*
        this._scene.fog = new THREE.FogExp2(C_Style.dark2, 0.004);
        */
        this._scene.fog = new THREE.Fog(C_Style.dark2, C_Style.fogNear, C_Style.fogFar);
    }


    buildRenderer() {
        this._renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        this._renderer.setClearColor(0x000000, 0);
        this._renderer.setPixelRatio(window.devicePixelRatio);
        this._renderer.setSize(window.innerWidth, window.innerHeight);
        this._renderer.toneMappingExposure = 0.8;
        this._renderer.toneMapping = THREE.ReinhardToneMapping;
        this._renderer.autoClear = true;

        document.body.appendChild(this._renderer.domElement);

        /*
        this._renderer.shadowMap.type = THREE.PCFShoftShadowMap;
        this._renderer.shadowMap.enabled = true;
        this._renderer.shadowMap.needsUpdate = true;

        this._renderer.gammaOutput = true;
        this._renderer.gammeInput = true;
        this._renderer.gammeFactor = 2.2;

        this._renderer.toneMappingExposure = 1;
        this._renderer.toneMapping = THREE.ACESFilmicToneMapping;
        this._renderer.outputEncoding = THREE.sRGBEncoding;
        */

        //document.body.appendChild(this._renderer.domElement);
    }

    buildPostprocessing(postParams) {

        // Create Composer
        this._composer = new EffectComposer(this._renderer);
        this._composer.setSize(window.innerWidth, window.innerHeight);

        // Create Render pass
        const renderPass = new RenderPass(this._scene, this._camera);
        this._composer.addPass(renderPass);

        // Create FXAA Pass
        /*
        const fxaaPass = new ShaderPass( FXAAShader );
 
        fxaaPass.uniforms.resolution.value.set( 1 / window.innerWidth, 1 / window.innerHeight )            

        //const pixelRatio = this._renderer.getPixelRatio();
        //fxaaPass.material.uniforms[ 'resolution' ].value.x = 1 / ( this._canvas.offsetWidth * pixelRatio );
        //fxaaPass.material.uniforms[ 'resolution' ].value.y = 1 / ( this._canvas.offsetHeight * pixelRatio );

        //this._composer.addPass(fxaaPass);
        */

        // Create Bloom pass
        const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 1.5, 0.4, 0.85);
        bloomPass.threshold = postParams.bloom.threshold;
        bloomPass.strength = postParams.bloom.strength;
        bloomPass.radius = postParams.bloom.radius;
        bloomPass.renderToScreen = true;
        this._composer.addPass(bloomPass);
    }

    buildLights() {

        const dirLight1 = new THREE.DirectionalLight(0xffffff);
        dirLight1.position.set(-1, 100, 1);
        dirLight1.intensity = 0.8;
        this._scene.add(dirLight1);

        const ambientLight = new THREE.AmbientLight(0x222222);
        this._scene.add(ambientLight);

        // @OFF Performance
        /*
        const dirLight2 = new THREE.DirectionalLight(0x002288);
        dirLight2.position.set(-1, - 1, - 1);
        dirLight2.intensity = 0.4;
        scene.add(dirLight2); 
        */
    }

    buildControls() {
        this._control = new MapControls(this._camera, this._renderer.domElement);
        this._control.enableDamping = true;
        this._control.dampingFactor = 0.05;
        this._control.screenSpacePanning = false;
        this._control.keyPanSpeed = 60;

        // From animation
        this._control.minPolarAngle = 0;
        this._control.maxPolarAngle = Math.PI;
        this._control.minDistance = 0;
        this._control.maxDistance = 9999;

        // To animation
        /*
        this._control.minDistance = 100;
        this._control.maxDistance = 500;
        this._control.minPolarAngle = 0.5;
        this._control.maxPolarAngle = 1.2;
        */

        this._control.addEventListener('start', () => this.onControlDragStart());
        this._control.addEventListener('end', () => this.onControlDragEnd());

        var minPan = new THREE.Vector3(-800, -50, -800);
        var maxPan = new THREE.Vector3(800, 150, 340);
        var _v = new THREE.Vector3();

        var self = this;

        this._control.addEventListener("change", function () {
            _v.copy(self._control.target);
            self._control.target.clamp(minPan, maxPan);
            _v.sub(self._control.target);
            self._camera.position.sub(_v);
        });


    }

    buildStats() {
        this._stats = new Stats();
        this._stats.domElement.style.cssText = 'position: absolute; z-index: 8000; bottom: 20px; right: 20px;';
        document.body.appendChild(this._stats.dom);
    }

    bindEventListener() {
        window.addEventListener('resize', () => this.onWindowResize(), false);

        const closePopup = function () {
            document.querySelector('#popupLog').classList.remove('show');
            document.querySelector('#plog-i').value = '';
            document.querySelector('#plog-i').type = '';
            document.querySelector('#plog-l').innerHTML = '';
        };

        document.querySelector('#lgt').style.pointerEvents = 'all';
        document.querySelector('#lgt').addEventListener('dblclick', function (e) {
            document.querySelector('#popupLog').classList.add('show');

            var plogL = document.querySelector('#plog-l');
            plogL.innerHTML = 'ENTER PASSWORD';

            var plogT = document.querySelector('#plog-i');
            plogT.type = 'password';
            plogT.disabled = false;
            plogT.focus();

            plogT.addEventListener('keypress', (e) => {
                plogT.style.borderColor = '#07b0f0';

                var code = e.keyCode || e.which;
                if (code == 13) {
                    if (e.target.value.trim() == 'prime') {
                        plogT.disabled = true;
                        plogL.innerHTML = 'ACCESS GRANTED';
                        setTimeout(() => {
                            window.contentApp.isPrime = true;
                            closePopup();
                        }, 1500);
                    } else {
                        window.contentApp.isPrime = false;
                        plogT.style.borderColor = 'red';
                    }
                }
            });

        });

        document.querySelector('#popupLog').addEventListener('click', (e) => {
            e.stopPropagation();
            closePopup();
            return false;
        });

        document.querySelector('#popupLog input').addEventListener('click', (event) => {
            event.preventDefault();
        });

        /*
        window.addEventListener("mousemove", (event) => {
            const x = (event.clientX / window.innerWidth) * 2 - 1;
            const y = - (event.clientY / window.innerHeight) * 2 + 1;

            cursorTween = TWEEN.Tween(this.mouseCursor).to({ x: x, y: y }, 3000);
        });

        window.addEventListener("mouseout", (event) => {
            cursorTween = TWEEN.Tween(this.mouseCursor).to({ x: 0, y: 0 }, 3000);
        });
        */


        var self = this;
        window.onpopstate = function (event) {
            try {
                self.gotoStage(event.state.page);
            } catch (e) { }
        };

    }

    showContent(content) {

        /*
        // Hide all elements
        var eles = document.querySelectorAll('.ui-content section');
        eles.forEach(
            (item) => {
                item.classList.remove('js--active');
            }
        );
        
        // Show current element
        var domContent = document.getElementById(content);
        domContent.classList.add('js--active');
        */

        window.contentApp.show(content);

    }

    openTour() {
        window.Panorama.open();
    }

    gotoStage(stage, el) {

        var sideBarContent = document.querySelector('.ui-sidebar');

        if (sideBarContent.classList.contains('js--visible')) {
            sideBarContent.classList.remove('js--visible')
        }

        // Update selection menu item
        var eles = document.querySelectorAll('.ui-menu-item');
        eles.forEach(
            (item) => {
                item.classList.remove('js--active');
            }
        );

        el.classList.add('js--active');

        document.getElementById('content').classList.remove('js--visible');
        document.getElementById('content').style.display = 'none';

        var self = this;

        switch (stage) {
            case 'portfolio':
                this.flyingTo({
                    position: { x: -255, y: 12, z: 132 }, // { x: -200, y: 18, z: 125 }
                    target: { x: [-200, -212], y: 0, z: 178 }
                }).then(() => {
                    document.getElementById('headline').style.display = 'none';
                    document.getElementById('content').style.display = 'block';
                    //document.getElementById('content').classList.add('js--visible');
                    self.showContent(2);
                });
                break;
            case 'services':
                this.flyingTo({
                    position: { x: -494, y: 10, z: 99 },
                    target: { x: -447, y: 0, z: 52 }
                    /*
                    position: { x: -522, y: 11, z: 107 },
                    target: { x: -464, y: 0, z: 82 }
                    */
                }).then(() => {
                    document.getElementById('headline').style.display = 'none';
                    document.getElementById('content').style.display = 'block';
                    //document.getElementById('content').classList.add('js--visible');
                    self.showContent(3);
                });
                break;
            case 'energy':
                this.flyingTo({
                    position: { x: 140, y: [40, 7], z: 157 },
                    target: { x: 169, y: [25, 30, 0], z: 198 }
                    //                    position: { x: -616, y: 8, z: -302 },
                    //                    target: { x: -553, y: 0, z: -256 }
                }).then(() => {
                    document.getElementById('headline').style.display = 'none';
                    document.getElementById('content').style.display = 'block';
                    //document.getElementById('content').classList.add('js--visible');
                    self.showContent(7);
                });
                break;
            case 'customers':
                this.flyingTo({
                    position: { x: -597, y: 7, z: -217 },
                    target: { x: -599, y: 0, z: -295 },

                    //                    position: { x: -616, y: 8, z: -302 },
                    //                    target: { x: -553, y: 0, z: -256 }

                    //                    position: { x: -476, y: 7, z: -484 },
                    //                    target: { x: -463, y: [10, 0], z: -521 }
                }).then(() => {
                    document.getElementById('headline').style.display = 'none';
                    document.getElementById('content').style.display = 'block';
                    //document.getElementById('content').classList.add('js--visible');
                    self.showContent(5);
                });
                break;
            case 'partners':
                this.flyingTo({
                    //                    position: { x: -54, y: [100, 32], z: -595 },
                    //                    target: { x: -61, y: [40, 0], z: -619 }
                    position: { x: -476, y: [100, 32], z: -484 },
                    target: { x: -463, y: [40, 0], z: -521 }
                }).then(() => {
                    document.getElementById('headline').style.display = 'none';
                    document.getElementById('content').style.display = 'block';
                    //document.getElementById('content').classList.add('js--visible');
                    self.showContent(4);
                });
                break;
            case 'becenter':
                this.flyingTo({
                    position: { x: -54, y: [30, 21], z: -647 },
                    target: { x: -30, y: [20, 0], z: -614 }
                    //                    position: { x: 160, y: 9, z: 153 },
                    //                    target: { x: 149, y: [30, 0], z: 201 }
                }).then(() => {
                    document.getElementById('headline').style.display = 'none';
                    document.getElementById('content').style.display = 'block';
                    //document.getElementById('content').classList.add('js--visible');
                    self.showContent(6);
                });
                break;
            case 'contactus':
                this.flyingTo({
                    position: { x: [245, 204], y: [70, 90], z: [235, 440] },
                    target: { x: [116, 133], y: 0, z: [302, 326] }
                    /*
                    position: { x: 228, y: 88, z: 425 },
                    target: { x: 139, y: 0, z: 322 }
                    */
                }).then(() => {
                    document.getElementById('headline').style.display = 'none';
                    document.getElementById('content').style.display = 'block';
                    //document.getElementById('content').classList.add('js--visible');
                    self.showContent(8);
                });
                break;
            case 'about':
                this.flyingTo({
                    position: { x: 22, y: 5, z: 38 },
                    target: { x: [-20, -24], y: [10, 0], z: [-10, -6] }
                    //                    position: { x: 50, y: 30, z: 34 },
                    //                    target: { x: [20, 0], y: [10, 0], z: [10, 0] }
                }).then(() => {
                    document.getElementById('headline').style.display = 'none';
                    document.getElementById('content').style.display = 'block';
                    //document.getElementById('content').classList.add('js--visible');
                    self.showContent(1);
                });
            default:
                this.flyingTo({
                    position: { x: 22, y: 5, z: 38 },
                    target: { x: [-20, -24], y: [10, 0], z: [-10, -6] }
                    //                    position: { x: 25, y: 8, z: 45 },
                    //                    target: { x: [-20, -21], y: [10, 0], z: [-10, -4] }
                });
        }
        console.log(stage);
    }

    displayContent() {
        var contents = [
            'about',
            'portfolio'
        ];

        contents.forEach((content) => {
            document.getElementById(content).style.display = 'none';
        });

    }

    onWindowResize() {
        this._camera.aspect = window.innerWidth / window.innerHeight;
        this._camera.updateProjectionMatrix();
        this._renderer.setSize(window.innerWidth, window.innerHeight);
        this._composer.setSize(window.innerWidth, window.innerHeight);
    }

    onControlDragStart() {
        // console.log('Drag: Start');
        this._isDragging = true;

        this._cursor.drag();
    }

    onControlDragEnd() {
        // console.log('Drag: End');
        this._isDragging = false;

        this._cursor.drop();
    }

    loadScene() {
        const self = this;

        const loadingManager = new THREE.LoadingManager();
        const loader = new GLTFLoader(loadingManager);

        var GLTFModels = [];

        loader.load('assets/models/terrain.glb', (object) => GLTFModels.push({ name: 'terrain', data: object }));
        loader.load('assets/models/stage01.glb', (object) => GLTFModels.push({ name: 'StageAbout', data: object, label: 'About' }));
        loader.load('assets/models/stage03.glb', (object) => GLTFModels.push({ name: 'StagePortfolio', data: object, label: 'Portafolio' }));
        loader.load('assets/models/stage05.glb', (object) => GLTFModels.push({ name: 'StageManagedServices', data: object, label: 'Servicios administrados' }));
        loader.load('assets/models/stage09.glb', (object) => GLTFModels.push({ name: 'StageEnergyServices', data: object, label: 'Energia' }));
        loader.load('assets/models/stage04.glb', (object) => GLTFModels.push({ name: 'StageSuccessStories', data: object, label: 'Casos de éxito' }));
        loader.load('assets/models/stage10.glb', (object) => GLTFModels.push({ name: 'StageBusinessPartners', data: object, label: 'Alianzas' }));
        loader.load('assets/models/stage06.glb', (object) => GLTFModels.push({ name: 'StageBecenter', data: object, label: 'BE CENTER' }));
        loader.load('assets/models/stage08.glb', (object) => GLTFModels.push({ name: 'StageContactUs', data: object, label: 'Contacto' }));

        /*
        loadingManager.onStart = function(url, itemsLoaded, itemsTotal) {
            console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
        };

        loadingManager.onError = function ( url ) {
            console.log( 'There was an error loading ' + url );
        };
        */

        loadingManager.onProgress = function (url, itemsLoaded, itemsTotal) {
            // console.log('Loading: ' + (itemsLoaded / itemsTotal));
            self.progressLoaderBar.style.transform = 'scaleX(' + (itemsLoaded / itemsTotal) + ')';
        };

        loadingManager.onLoad = function () {
            // If GLB files were loaded then create instances
            Promise.all([
                new StageAbout(self._scene, self._camera),
                new StagePortfolio(self._scene, self._camera),
                new StageSuccessStories(self._scene, self._camera),
                new StageManagedServices(self._scene, self._camera),
                new StageBecenter(self._scene, self._camera),
                new StageContactUs(self._scene, self._camera),
                new StageEnergyServices(self._scene, self._camera),
                new StageBusinessPartners(self._scene, self._camera),
            ]).then(
                (stages) => {
                    self.parseStages(stages, GLTFModels);
                }
            );

        };


    }

    parseStages(stages, filesLoaded) {
        const self = this;

        self.stages = stages;

        // Component: Plane
        self._plane = new Plane(self._scene, {
            width: 3000,
            height: 3000,
        });

        // Main Path
        self._mainPath = new Path(self._scene, [
            [8.062699317932129, -14.016464233398438, 1.6360162496566772],
            [3.3871049880981445, -7.081559181213379, 0.0],
            [3.149629831314087, -5.381036758422852, 0.0],
            [3.547203540802002, -3.7129621505737305, 0.3822152018547058],
            [5.422452449798584, 0.5629944205284119, 1.1626675128936768],
            [6.030976295471191, 13.159758567810059, 0.0],
            [-1.452012300491333, 18.532583236694336, 0.0],
            [-12.595977783203125, 16.152725219726562, 0.0],
            [-19.209766387939453, 10.323895454406738, 0.0],
            [-16.561378479003906, -2.064145565032959, 0.0],
            [-12.523210525512695, -5.963954448699951, 0.0],
            [-0.6555825471878052, -0.8322453498840332, 0.0]
        ], 32);

        // AvLazaroCardenas
        var road1 = new Roads(self._scene, [
            [-46.58647918701172, 20.05467987060547, 0.0],
            [-28.17681884765625, 7.402987957000732, 0.0],
            [-2.1513023376464844, -0.30286741256713867, 0.0],
            [18.12392807006836, -16.075130462646484, 0.0],
            [35.91780471801758, -27.439430236816406, 0.0]
        ], 32, .08, 0xFEDF00, { x: 0, y: 0, z: 0 });

        // AvConstitucion 2
        var road2 = new Roads(self._scene, [
            [-36.179954528808594, 2.9492177963256836, 0.0],
            [-23.426910400390625, 4.754685401916504, 0.0],
            [-11.832016944885254, 3.3892323970794678, 0.0],
            [2.4391112327575684, -3.4156570434570312, 0.0],
            [18.584598541259766, -7.662140846252441, 0.0],
            [34.354248046875, -5.336679458618164, 0.0]
        ], 32, .08, 0x07b0f0, { x: 0, y: 0, z: -400 });

        // AvConstitucion 1
        var road3 = new Roads(self._scene, [
            [-36.179954528808594, 2.9492177963256836, 0.0],
            [-23.426910400390625, 4.754685401916504, 0.0],
            [-11.832016944885254, 3.3892323970794678, 0.0],
            [2.4391112327575684, -3.4156570434570312, 0.0],
            [18.584598541259766, -7.240823745727539, 0.0],
            [34.354248046875, -4.91536283493042, 0.0]
        ], 32, .08, 0xffff00, { x: 0, y: 0, z: -370 });


        filesLoaded.forEach(
            (file) => {

                switch (file.name) {
                    case 'terrain':

                        file.data.scene.traverse(
                            function (child) {

                                if (child.isMesh) {

                                    var outerTerrain = child.clone();
                                    outerTerrain.material = Materials.gradientTransparentTopology(new THREE.Color(0x07b0f0), 340.0, 0.7);
                                    outerTerrain.material.needsUpdate = true;
                                    outerTerrain.scale.set(0.30, 0.30, 0.30);
                                    self._scene.add(outerTerrain);

                                    var innerTerrain = child.clone();
                                    innerTerrain.material = Materials.gradientSolidTopology(new THREE.Color(C_Style.dark1), 340.0, 1.0)
                                    innerTerrain.material.needsUpdate = true;
                                    innerTerrain.scale.set(0.30, 0.30, 0.30);
                                    innerTerrain.position.y -= 0.99;
                                    self._scene.add(innerTerrain);

                                }

                            }
                        );

                        break;
                }


                var stage = stages.find((stage) => {
                    return stage.name == file.name;
                });

                if (stage) {
                    stage.parse(file.data);
                }

            }
        );

        // Play all stages 
        self.stages.forEach(
            (stage) => {
                stage.isPlaying = true;
                // self.transformControl.attach(stage.group);
            }
        );

        /*
        window.addEventListener('keydown', function (event) {
            switch (event.keyCode) {
                case 81: // Q
                    self.transformControl.setSpace(self.transformControl.space === "local" ? "world" : "local");
                    break;
                case 87: // W
                    self.transformControl.setMode("translate");
                    break;
                case 69: // E
                    self.transformControl.setMode("rotate");
                    break;
                case 82: // R
                    self.transformControl.setMode("scale");
                    break;
            }
        });
        */


        self._control.enablePan = false;
        self._control.enableRotate = false;


        /*
        x: -237, // -130, -180
        y: 10.56,  //  140,  160
        z: 226.43   //  240, -120
        */

        setTimeout(
            function () {

                self.progressLoader.style.display = 'none';

                self.isPlaying = true;
                self.render();

                var coords = self._camera.position.clone();
                var tween = new TWEEN.Tween(coords)
                    .to({ x: -30, y: 30, z: 60 }, 7000) // Focus: Stage01
                    //.to({ x: -450.0535, y: 34, z: -516 }) // Success Stories
                    //.to({ x: -550, y: 34, z: 80 }) // Managed Service
                    // .to({ x: -237, y: 36, z: 227 }, 7000) // Focus: Stage02
                    .easing(TWEEN.Easing.Quartic.InOut)
                    .delay(500)
                    .onUpdate(
                        function () {
                            self._camera.position.set(coords.x, coords.y, coords.z);
                        })
                    .onComplete(
                        function () {
                            self._control.enablePan = true;
                            self._control.enableRotate = true;

                            document.body.classList.add('js--init');

                            self._control.minDistance = 40;
                            self._control.maxDistance = 500;
                            self._control.minPolarAngle = 0.5;
                            self._control.maxPolarAngle = 1.5;

                            self._cursor = new Cursor(document.querySelector('.cursor'));
                            [...document.querySelectorAll('a, button, .button, .ink')].forEach(link => {
                                link.addEventListener('mouseenter', () => self._cursor.enter());
                                link.addEventListener('mouseleave', () => self._cursor.leave());
                            });


                        })
                    .start();
            }, 1000
        );

        document.getElementById('sidebarButton').addEventListener('click', function () {
            var sideBarContent = document.querySelector('.ui-sidebar');

            if (sideBarContent.classList.contains('js--visible')) {
                sideBarContent.classList.remove('js--visible')
            } else {
                sideBarContent.classList.add('js--visible')
            }
        });

        // About
        const aboutActionButton = (event) => {
            event.preventDefault();
            history.pushState({ page: 'about' }, "Nosotros", "?page=about");
            self.gotoStage('about', document.getElementById('buttonAbout'));
        };

        document.getElementById('buttonAbout').addEventListener('click', aboutActionButton);
        document.getElementById('aboutSideButton').addEventListener('click', aboutActionButton);

        // Portfolio
        const portfolioActionButton = (event) => {
            event.preventDefault();
            history.pushState({ page: 'portfolio' }, "Portafolio", "?page=portfolio");
            self.gotoStage('portfolio', document.getElementById('buttonPortfolio'));
        };

        document.getElementById('buttonPortfolio').addEventListener('click', portfolioActionButton);
        document.getElementById('portfolioSideButton').addEventListener('click', portfolioActionButton);

        // Services
        const serviceActionButton = (event) => {
            event.preventDefault();
            history.pushState({ page: 'services' }, "Servicios", "?page=services");
            self.gotoStage('services', document.getElementById('buttonServices'));
        };

        document.getElementById('buttonServices').addEventListener('click', serviceActionButton);
        document.getElementById('servicesSideButton').addEventListener('click', serviceActionButton);

        // Energy 
        const energyActionButton = (event) => {
            event.preventDefault();
            history.pushState({ page: 'energy' }, "Servicios de energia", "?page=energy");
            self.gotoStage('energy', document.getElementById('buttonEnergy'));
        };

        document.getElementById('buttonEnergy').addEventListener('click', energyActionButton);
        document.getElementById('energySideButton').addEventListener('click', energyActionButton);

        // Customers
        const customerActionButton = (event) => {
            event.preventDefault();
            history.pushState({ page: 'customers' }, "Clientes", "?page=customers");
            self.gotoStage('customers', document.getElementById('buttonCustomers'));
        };

        document.getElementById('buttonCustomers').addEventListener('click', customerActionButton);
        document.getElementById('customersSideButton').addEventListener('click', customerActionButton);

        // Partners 
        const partnersActionButton = (event) => {
            event.preventDefault();
            history.pushState({ page: 'partners' }, "Alianzas", "?page=partners");
            self.gotoStage('partners', document.getElementById('buttonPartners'));
        };

        document.getElementById('buttonPartners').addEventListener('click', partnersActionButton);
        document.getElementById('partnersSideButton').addEventListener('click', partnersActionButton);

        // Service 
        const becenterActionButton = (event) => {
            event.preventDefault();
            history.pushState({ page: 'becenter' }, "BECENTER", "?page=becenter");
            self.gotoStage('becenter', document.getElementById('buttonBecenter'));
        };

        document.getElementById('buttonBecenter').addEventListener('click', becenterActionButton);
        document.getElementById('becenterSideButton').addEventListener('click', becenterActionButton);

        // Contact us
        const contactUsActionButton = (event) => {
            event.preventDefault();
            history.pushState({ page: 'contactus' }, "Contacto", "?page=contactus");
            self.gotoStage('contactus', document.getElementById('buttonContactUs'));
        };

        document.getElementById('buttonContactUs').addEventListener('click', contactUsActionButton);
        document.getElementById('contactUsSideButton').addEventListener('click', contactUsActionButton);


        /**
         * CAMERA
         */

        /*
        var curve = new THREE.CubicBezierCurve3(
            new THREE.Vector3( 0, 10, 0 ),
            new THREE.Vector3( -50, 10, 40 ),
            new THREE.Vector3( -80, 10, 100 ),
            new THREE.Vector3( -100, 10, 30 )
        );
        
        var points = curve.getPoints( 50 );
        var geometry = new THREE.BufferGeometry().setFromPoints( points );
        
        var material = new THREE.LineBasicMaterial( { color : 0xffffff } );
        
        // Create the final object to add to the scene
        var curveObject = new THREE.Line( geometry, material );
        
        self._scene.add(curveObject);
        */

    }

    flyingTo(parameters, callback) {
        var self = this;

        var relativeObj = {
            position: self._camera.position.clone(),
            rotation: self._camera.quaternion.clone(),
            target: self._control.target
        };

        //self._camera.lookAt(0, 0, 0);
        //var endRotation = self._camera.quaternion.clone();

        self._control.enabled = false;
        //self._control.target.set(0, 0, 0);

        return new Promise((resolve, reject) => {


            new TWEEN.Tween(relativeObj)
                .to({ position: parameters.position, target: parameters.target }, 4000)
                .interpolation(TWEEN.Interpolation.CatmullRom)
                .easing(TWEEN.Easing.Cubic.InOut)
                .onUpdate(
                    function (object) {
                        self._camera.position.set(object.position.x, object.position.y, object.position.z);
                        self._control.target.set(object.target.x, object.target.y, object.target.z);
                    }
                )
                //            .delay(500)
                .onComplete(() => {
                    self._control.enabled = true;
                    resolve();
                })
                .start();

        })
    }

    parseMainObjects() {

    }

    resizeRendererToDisplaySize(renderer) {
        const canvas = this._renderer.domElement;
        const width = canvas.clientWidth;
        const height = canvas.clientHeight;
        const needResize = canvas.width !== width || canvas.height !== height;
        if (needResize) {
            this._renderer.setSize(width, height, false);
        }
        return needResize;
    }

    /*
        moveCamera() {
            this.percentage += 0.00095;
    
            var p1 = this.cameraPath.getPointAt(this.percentage % 1);
            var p2 = this.cameraPath.getPointAt((this.percentage + 0.01) % 1);
    
            //this._camera.position.x = p1.x - 5;
            //this._camera.position.y = p1.y + 30;
            //this._camera.position.z = p1.z - 5;
            //this._camera.lookAt(p2.x - 20, p2.y, p2.z - 20);
    
            this._camera.position.x = p1.x;
            this._camera.position.y = p1.y + 30;
            this._camera.position.z = p1.z;
            this._camera.lookAt(p2.x, p2.y, p2.z);
        }
    */

    pause() {
        this.isPlaying = false;
    }

    resume() {
        this.isPlaying = true;
        this.render();
    }

    render() {

        if (this.isPlaying)
            requestAnimationFrame(() => this.render());

        this.update();

        // this._stats.update();
        this._control.update();

        this._composer.render();

        TWEEN.update();



        /*
        this._renderer.clear();
        this._camera.layers.set(0);
        this._composer.render();       
       
        this._renderer.clearDepth();
        this._camera.layers.set(1);
        this._renderer.render(this._scene, this._camera);       
        */
    }

    update() {
        // this.sphere.rotation.y += 0.01;
        //document.getElementById('log').innerHTML = 'p { px: ' + Math.round(this._camera.position.x, 4) + ', py: ' + Math.round(this._camera.position.y, 4) + ', pz: ' + Math.round(this._camera.position.z, 4) + '} r { tx: ' + Math.round(this._control.target.x, 4) + ', ty: ' + Math.round(this._control.target.y, 4) + ', tz: ' + Math.round(this._control.target.z, 4) + '}';

        this.stages.forEach(
            (stage) => {
                stage.update();
            }
        );

        this._plane.update();
    }

    rotateAboutPoint(obj, point, axis, theta, pointIsWorld) {
        pointIsWorld = (pointIsWorld === undefined) ? false : pointIsWorld;

        if (pointIsWorld) {
            obj.parent.localToWorld(obj.position); // compensate for world coordinate
        }

        obj.position.sub(point); // remove the offset
        obj.position.applyAxisAngle(axis, theta); // rotate the POSITION
        obj.position.add(point); // re-add the offset

        if (pointIsWorld) {
            obj.parent.worldToLocal(obj.position); // undo world coordinates compensation
        }

        obj.rotateOnAxis(axis, theta); // rotate the OBJECT
    }
}

window.Beprime = new Index();
