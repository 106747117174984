// Core
import { C_Style } from './constans.js';
import * as THREE from 'three';
import { MathUtils } from 'three/src/math/MathUtils.js';

export class Clouds {
    constructor(scene, camera) {

        this.group = new THREE.Group();

        const cloudTexture = new THREE.TextureLoader().load('assets/textures/cloud-03.png');

        const cloudMaterial1 = new THREE.MeshBasicMaterial({
            map: cloudTexture,
            color: 0xffffff,
            opacity: 0.15,
            transparent: true,
            side: THREE.DoubleSide,
            blending: THREE.AdditiveBlending,
            depthTest: true,
            depthWrite: false,
            premultipliedAlpha: true
        });

        const cloudMaterial2 = new THREE.MeshPhongMaterial({
            map: cloudTexture,
            color: 0xffffff,
            opacity: 0.20,
            transparent: true,
            side: THREE.DoubleSide,
            blending: THREE.AdditiveBlending,
            depthTest: true,
            emissive: 5.0,
            depthWrite: false,
            premultipliedAlpha: false
        });

        // Cloud 1
        this.cloud1 = new THREE.Mesh(new THREE.PlaneGeometry(40, 40, 1), cloudMaterial1);
        this.cloud1.rotation.x = Math.PI / 2;
        this.cloud1.position.set(0, 18, 0);
        this.group.add(this.cloud1);

        // Cloud 2
        this.cloud2 = new THREE.Mesh(new THREE.PlaneGeometry(50, 30, 1), cloudMaterial2);
        this.cloud2.rotation.x = Math.PI / 2;
        this.cloud2.position.set(0, 16, 0);
        this.group.add(this.cloud2);

        return this;
    }

    update(time) {
        this.cloud1.position.x = 25 + Math.sin(time * .00015) - 25;
        this.cloud1.material.opacity = MathUtils.lerp(0.2, 0, Math.sin(time * .00015));

        this.cloud2.material.opacity = MathUtils.lerp(0.5, 0, MathUtils.smoothstep(Math.abs(this.cloud2.position.x), -10, 20));
        this.cloud2.position.x += 0.04;

        if (this.cloud2.position.x > 20)
            this.cloud2.position.x = -30;
    }
}