import * as THREE from 'three';
import { MathUtils } from 'three/src/math/MathUtils.js';
import { Border } from './border.js';
import { Materials } from './materials.js';
import { Particles } from './particles.js';
import { Clouds } from './clouds.js';

export class StageEnergyServices {

    /**
     * Becenter
     * 
     * @param {THREE.Scene} scene 
     * @param {THREE.Camear} camera 
     * @param {THREE.LoadingManager} loadingManager 
     * @returns 
     */

    constructor(scene, camera, loadingManager) {
        var self = this;

        this.name = 'StageEnergyServices';

        this._scene = scene;
        this._camera = camera;

        // The main clock
        this._clock = new THREE.Clock();

        // Stage container
        this.group = new THREE.Group();

        // Add stage container to main scene
        this._scene.add(this.group);

        // Set stage position in local coordinates
        this.group.position.set(150.0535, 0, 199.965);
//        this.group.position.set(-466.7, 0, 83.41);

        //this.group.rotation.y = 0.5;

        // Swaps to calculate distances
        this._vectorSource = new THREE.Vector3(0, 0, 0);
        this._vectorTarget = new THREE.Vector3(0, 0, 0);

        // By default stage is not playing
        this.isPlaying = false;

        return new Promise((resolve, reject) => {
            self.buildProps();
            resolve(self);
        });

    }

    /**
     * Build all props
     * 
     * @return void
     */
    buildProps() {

        // @Build: Outer circle [orange]
        var outerCircle = new THREE.Mesh(
            new THREE.TorusGeometry(4.0, 0.01, 8, 100),
            new THREE.MeshStandardMaterial({ color: 0xfe4f0e, emissive: 0x410c0c, emissiveIntensity: 2.3 })
        );

        outerCircle.position.y = 0;
        outerCircle.position.z = 0;
        outerCircle.rotation.x = Math.PI / 2;
        outerCircle.scale.set(9, 9, 9);

        this.group.add(outerCircle);

        // @Build: Inner circle [white] 
        var borderCircle = new THREE.Line( // Mesh 
            new Border([
                [-1.0041128396987915, -0.05757945403456688, 0.0],
                [-0.8427323698997498, 0.32761314511299133, 0.0],
                [-0.7551177144050598, 0.6961455345153809, 0.0],
                [0.49895960092544556, 1.2207820415496826, 0.0],
                [0.567420482635498, 0.5923076272010803, 0.0],
                [1.2015280723571777, 0.0, 0.0],
                [1.0138541460037231, -0.371755987405777, 0.0],
                [0.47435736656188965, -0.8411349654197693, 0.0],
                [0.8109151721000671, -1.4601231813430786, 0.0],
                [0.5263993740081787, -1.5029339790344238, 0.0],
                [-0.8908694386482239, -0.8145706653594971, 0.0],
                [-1.2884035110473633, -0.48287075757980347, 0.0],
            ], .05, 50),
            new THREE.MeshStandardMaterial({ color: 0x07b0f0 /* , emissive: 0x07b0f0, emissiveIntensity: 5.0  */ })
            // new THREE.LineBasicMaterial({ color: 0x07b0f0, emissive: 0x07b0f0, emissiveIntensity: 5.0 })
        );

        borderCircle.position.set(0, 0, 0);
        this.group.add(borderCircle);

        // Particles
        this.particles = new Particles(this._scene, this._camera, 60);
        this.particles.group.position.set(0, 0, 0);
        this.group.add(this.particles.group);

        // Clouds
        this.clouds = new Clouds(this._scene, this._camera);
        this.group.add(this.clouds.group);
    }

    /**
     * Parse all elements in GLB file and insert into group
     * 
     * @param {THREE.Object3D} object 
     * @returns Promise 
     */
    parse(object) {
        var self = this;

        return new Promise(
            (resolve, reject) => {

                object.scene.traverse(
                    function (item) {

                        if (!item.isMesh)
                            return;

                        var child = item.clone();

                        switch (child.name) {
                            case 'Dome_node':
                                child.material = Materials.blueGlowLight();
                                child.material.needsUpdate = true;
                                //                                child.position.z -= 1.0;
                                child.scale.set(0.3, 0.15, 0.3);
                                self.group.add(child);

                                // Orange outter circle
                                var TorusMesh = new THREE.Mesh(
                                    new THREE.TorusGeometry(31.5, 0.02, 8, 100),
                                    new THREE.MeshBasicMaterial({ color: 0xffffff })
                                );

                                TorusMesh.rotation.x = Math.PI / 2;

                                self.group.add(TorusMesh);

                                break;

                            case 'Building_node':
                            case 'Building_Base_node':
                                child.material = Materials.solidBuildingFlat();
                                child.material.transparent = true;
                                child.material.opacity = 0.8;
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);

                                var wireframeChild = new THREE.LineSegments(
                                    new THREE.EdgesGeometry(child.geometry),
                                    Materials.wireframeSolidBlue()
                                );
                                wireframeChild.scale.set(0.3, 0.3, 0.3);
                                self.group.add(wireframeChild);

                                break;

                            case 'Building_Base_Line_node':

                                child.material = Materials.highlightBuilding();
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);

                                break;


                            case 'Tower_Inner_node':

                                child.material = Materials.highlightBuilding();
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);

                                break;
                            case 'Tower_node':

                                child.material = new THREE.MeshStandardMaterial({
                                    color: 0x07b0f0,
                                    transparent: true,
                                    opacity: 0.5,
                                    wireframe: true,
                                    emissive: 0x07b0f0,
                                    emissiveIntensity: 2.0
                                });
                                child.material.needsUpdate = true;
                                child.scale.set(0.3, 0.3, 0.3);
                                self.group.add(child);

                                break;
                            case 'CablesTorre_node':
                            case 'CablesPoste_node':
                            default:
                                var Mesh = new THREE.Mesh(
                                    child.geometry,
                                    Materials.blueWireframe()
                                );
                                Mesh.scale.set(0.3, 0.3, 0.3);
                                self.group.add(Mesh);
                        }


                        resolve(this);
                    }
                );
            }
        );
    }


    /**
     * Return if object is near visible to the camera.
     * 
     * @returns bool
     */
    hasToUpdate() {
        this._vectorTarget.set(
            this._camera.position.x,
            this._camera.position.y,
            this._camera.position.z
        );
        var distance = this.group.position.distanceTo(this._vectorTarget);

        this.globalOpacity = MathUtils.smoothstep(distance, 70, 200);

        return distance < 140;
    }

    /**
     * Play all secondary animations
     * 
     * @returns void
     */
    resume() {
        if (this.isPlaying)
            return;
        this.particles.resume();
    }

    /**
     * Puase all secondary animations
     * 
     * @returns void
     */
    pause() {
        if (this.isPlaying == false)
            return;
        this.particles.pause();
    }

    /**
     * Update loop
     * 
     * @returns void
     */
    update() {

        // Update secondary animations if elements are near visible
        if (this.hasToUpdate()) {
            this.render();

            // Play secondary animations
            this.resume();
            this.isPlaying = true;
        } else {

            // Pause secondary animations
            this.pause();
            this.isPlaying = false;
        }

        // Call to render all primary elements
        this.renderAll();
    }

    /**
     * Render only visible elements
     * 
     * @returns void
     */
    render() {
        var time = this._clock.getDelta();

        this.clouds.update(time);
        this.particles.update(time, this.globalOpacity);

        // Custom code

    }

    /**
     * Render only primary elements
     * 
     * @returns void
     */
    renderAll() {
        this.particles.renderAll(this.globalOpacity);
    }



}