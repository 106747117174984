//import * as THREE from 'https://cdn.skypack.dev/three';
import * as THREE from 'three'

class Hotspot {

    constructor(scene, container, options) {

        var div = document.createElement('div');

        div.setAttribute('class', 'ui-hotspot');
        //div.setAttribute('hotspot', JSON.stringify(options));
        div.setAttribute('label', options.label);
        div.setAttribute('link', options.link);

        var innerDiv = document.createElement('div');
        innerDiv.setAttribute('class', 'ui-hotspot-inner');
        div.appendChild(innerDiv);

        if( options.type == 'info' ) {
            div.classList.remove('ui-hotspot');
            div.classList.add('ui-hotspot-info');

            innerDiv.innerHTML = options.description;
        }

        if (options.type == 'image') {
            div.classList.add('h-image');

            var divContainer = document.createElement('div');
            var divImage = document.createElement('img');
            divImage.setAttribute('src', options.image);
            divContainer.appendChild(divImage);

            div.appendChild(divContainer);
        }

        if (options.type == 'video') {
            div.classList.add('h-video');

            var divContainerVideo = document.createElement('div');
            var divVideo = document.createElement('video');
            divVideo.setAttribute('src', options.video);
            divVideo.setAttribute('controls', 'true');
            divContainerVideo.appendChild(divVideo);

            div.appendChild(divContainerVideo);

            div.onmouseout = function (event) {
                if (options.type == 'video') {
                    divVideo.pause();
                    divVideo.currentTime = 0;
                }
            };
        }

        if( options.type != 'info' ) {
            div.onclick = function (event) {

                var link = div.getAttribute('link');
                window.Panorama.goto( link );

                /*
                if (options.type != 'video') {
                    document.getElementById('title').style.display = 'none';
                    document.querySelector('#name').innerHTML = options.name;
                    document.querySelector('#description').innerHTML = options.description;
                }
                */
            };
        }

        container.appendChild(div);

        this.obj = new THREE.Object3D();
        this.obj.position.set(options.position.x, options.position.y, options.position.z);
        this.obj.dom = div;

        return this.obj;

    }
}

export class HotspotManager {

    constructor(scene, camera, container) {

        this.scene = scene;
        this.camera = camera;
        this.container = container;

        this.hotspots = new THREE.Group();
        this.scene.add(this.hotspots);
    }

    reset() {
        for (var i = this.hotspots.children.length - 1; i >= 0; i--) {
            this.hotspots.remove(this.hotspots.children[i]);
        }

        document.querySelectorAll('.ui-hotspot, .ui-hotspot-info').forEach(
            (hotspotElement) => {
                hotspotElement.parentNode.removeChild(hotspotElement);
            }
        );
    }

    add(hotspotOptions) {
        this.hotspots.add(new Hotspot(this.scene, this.container, hotspotOptions));
    }

    hotspotVisible(object) {
        this.camera.updateMatrix();
        this.camera.updateMatrixWorld();

        var frustum = new THREE.Frustum();

        frustum.setFromProjectionMatrix(
            new THREE.Matrix4().multiplyMatrices(this.camera.projectionMatrix, this.camera.matrixWorldInverse)
        );

        return frustum.containsPoint(new THREE.Vector3(object.position.x, object.position.y, object.position.z));
    };


    update() {

        var self = this;

        this.hotspots.children.forEach(
            function (hotspot) {
                if (hotspot instanceof THREE.Mesh || hotspot instanceof THREE.Object3D) {

                    // Transform 3D position to 2D
                    var vector3D = new THREE.Vector3();
                    hotspot.localToWorld(vector3D);
                    vector3D.project(self.camera);

                    // Calculate the vector (x,y)
                    var posX = Math.round((vector3D.x + 1) * self.container.offsetWidth / 2) - 10;
                    var posY = Math.round((1 - vector3D.y) * self.container.offsetHeight / 2) - 10;

                    // Check if hotspot is visible
                    if (self.hotspotVisible(hotspot)) {
                        hotspot['dom'].style.display = 'block';

//                        hotspot['dom'].style.left = posX + 'px';
//                        hotspot['dom'].style.top = posY + 'px';

                        hotspot['dom'].style.transform = 'translate(' + posX + 'px,' + posY + 'px)'; 
                    } else {
                        hotspot['dom'].style.display = 'none';
                    }

                }
            }
        );

    }

}
